import { APICore } from "./apiCore";

const api = new APICore();

function getAllIssue(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
  issueType: string;
  assetId?: number;
  userId?: any;
}) {
  const baseUrl = "/api/Issue/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getIssue(params: { id: number | string }) {
  const baseUrl = "/api/Issue/Get";
  return api.get(`${baseUrl}`, params);
}

function saveIssue(params: any) {
  const baseUrl = "/api/Issue/Save";
  return api.create(`${baseUrl}`, params);
}

function forwardRequest(params: any) {
  const baseUrl = `/api/Issue/ForwardRequest?id=${params.id}&userId=${params.userId}`;
  return api.create(`${baseUrl}`, {});
}

function deleteIssue(id: string | number) {
  const baseUrl = "/api/Issue/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

function ReassignAllIssues(params: any) {
  const baseUrl = `/api/Issue/ReassignAllIssues?fromUserId=${params.fromUserId}&toUserId=${params.toUserId}`;
  return api.create(`${baseUrl}`, {});
}

export {
  getAllIssue,
  getIssue,
  saveIssue,
  forwardRequest,
  deleteIssue,
  ReassignAllIssues,
};
