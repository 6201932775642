import { APICore } from "./apiCore";

const api = new APICore();

// team
function getTeamList(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
}) {
  const baseUrl = "/api/Team/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getTeamDetails(params: { id: number | string }) {
  const baseUrl = "/api/Team/Get";
  return api.get(`${baseUrl}`, params);
}

function saveTeamDetails(params: any) {
  const baseUrl = "/api/Team/Save";
  return api.create(`${baseUrl}`, params);
}

function deleteTeamDetails(id: string | number) {
	const baseUrl = "/api/Team/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

export { getTeamList, getTeamDetails, saveTeamDetails, deleteTeamDetails };
