import React, { useState } from "react";
import {
    FormControl,
    InputGroup,
} from "react-bootstrap";

let debounce: any = undefined;
const Search = ({ pagination }: { pagination: any; }) => {
    const [value, setValue] = useState<any>("");
    const onChange = (value: any) => {
        if(debounce){
            clearInterval(debounce);
        }
        debounce = setTimeout(() => {
            if (pagination?.handleSearch) {
                pagination?.handleSearch(value || "");
            }
        }, 400);
    }
    return (
        <InputGroup className="align-items-center bg-light" style={{position:"relative",right:"20px",  width: "240px"}}>
            <i className="bi bi-search icon-search bg-light ms-2"></i>
            <FormControl
            
                className="search-input bg-light border-0"
                type="search"
                value={value}
              
                onChange={(e:any)=>{
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                // placeholder={`${pagination?.totalItems || ""} records...`}
                placeholder="type and enter to search"
            />
        </InputGroup>
    );
};

export default Search;
