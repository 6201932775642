import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useUserPageAndRow } from "helpers";

interface PaginationProps {
  tableProps: {
    pageCount: number,
    pageOptions: Array<any>
    setPageSize: (page: number) => void,
    gotoPage: (page: number) => void,
    state: {
        pageIndex: number,
        pageSize: number,
    }
  };
  sizePerPageList: {
    text: string;
    value: number;
  }[];
}

const Pagination = ({ tableProps, sizePerPageList }: PaginationProps) => {
  const location = useLocation();
  const { userPageNumber } = useUserPageAndRow();
  /**
   * pagination count , index
   */
  const [pageCount, setPageCount] = useState<number>(tableProps.pageCount);
  const [pageIndex, setPageIndex] = useState<number>(
    tableProps.state.pageIndex
  );

  useEffect(() => {
    setPageCount(tableProps.pageCount);
    setPageIndex(tableProps.state.pageIndex);
  }, [tableProps.pageCount, tableProps.state.pageIndex]);

  /**
   * get filter pages
   */
  const filterPages = useCallback(
    (visiblePages: any, totalPages: number) => {
      return visiblePages.filter((page: any) => page <= pageCount);
    },
    [pageCount]
  );

  /**
   * handle visible pages
   */
  const getVisiblePages = useCallback(
    (page: number | null, total: number) => {
            if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
      }  
      if(Number(userPageNumber) && Number(userPageNumber)! > 5) {
        console.log("WORKING")
        if (Number(userPageNumber)! % 5 >= 0 && Number(userPageNumber)! > 4 && Number(userPageNumber)! + 2 < total) {
          return [1, Number(userPageNumber)! - 1, Number(userPageNumber)!, Number(userPageNumber)! + 1, total];
        } else if (Number(userPageNumber)! % 5 >= 0 && Number(userPageNumber)! > 4 && Number(userPageNumber)! + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total];
        } else {
          return [1, 2, 3, 4, 5, total];
        }
      }
      // keeping this as some tables still works on this logic
      else {
        if (page! % 5 >= 0 && page! > 4 && page! + 2 < total) {
          return [1, page! - 1, page!, page! + 1, total];
        } else if (page! % 5 >= 0 && page! > 4 && page! + 2 >= total) {
          return [1, total - 3, total - 2, total - 1, total];
        } else {
          return [1, 2, 3, 4, 5, total];
        }
      }
    },
    [filterPages, userPageNumber]
  );

  /**
   * handle page change
   * @param page - current page
   * @returns
   */
  const changePage = (page: number) => {
    const activePage = pageIndex + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = getVisiblePages(page, pageCount);
    setVisiblePages(filterPages(visiblePages, pageCount));

    tableProps.gotoPage(page - 1);
  };

  useEffect(() => {
    const visiblePages = getVisiblePages(null, pageCount);
    setVisiblePages(visiblePages);
  }, [pageCount, getVisiblePages, userPageNumber]);

  const [visiblePages, setVisiblePages] = useState<number[]>(
    getVisiblePages(null, pageCount)
  );
  const activePage: number = pageIndex + 1;

  const generateLink = () => {
    try{
      return `${location.pathname}${location.search}`;
    }catch(error){
      console.log('error', error);
    }
    return '#';
  }

  return (
    <>
      <div className="d-lg-flex align-items-center text-center pb-1 pt-1">
        {sizePerPageList.length > 0 && (
          <div className="d-inline-block me-3">
            <label className="me-1">Display :</label>
            <select
              value={tableProps.state.pageSize}
              onChange={(e: any) => {
                tableProps.setPageSize(Number(e.target.value));
              }}
              className="form-select d-inline-block w-auto"
            >
              {(sizePerPageList || []).map((pageSize, index) => {
                return (
                  <option key={index} value={pageSize.value}>
                    {pageSize.text}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <span className="me-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {tableProps.pageOptions.length}
          </strong>{" "}
        </span>


       


        <ul className="pagination pagination-rounded d-inline-flex ms-auto">
          <li
            key="prevpage"
            className={classNames("page-item", "paginate_button", "previous", {
              disabled: activePage === 1,
            })}
            onClick={() => {
              if (activePage === 1) return;
              changePage(activePage - 1);
            }}
          >
            <Link to={generateLink()} className="page-link">
              <i className="uil uil-angle-left"></i>
            </Link>
          </li>
          {(visiblePages || []).map((page, index, array) => {
            return array[index - 1] + 1 < page ? (
              <React.Fragment key={page}>
                <li className="page-item disabled d-none d-xl-inline-block">
                  <Link to={generateLink()} className="page-link">
                    ...
                  </Link>
                </li>
                <li
                  className={classNames(
                    "page-item",
                    "d-none",
                    "d-xl-inline-block",
                    {
                      active: activePage === page,
                    }
                  )}
                  onClick={(e: any) => changePage(page)}
                >
                  <Link to={generateLink()} className="page-link rounded">
                    {page}
                  </Link>
                </li>
              </React.Fragment>
            ) : (
              <li
                key={page}
                className={classNames(
                  "page-item",
                  "d-none",
                  "d-xl-inline-block",
                  {
                    active: activePage === page,
                  }
                )}
                onClick={(e: any) => changePage(page)}
              >
                <Link to={generateLink()} className="page-link rounded">
                  {page}
                </Link>
              </li>
            );
          })}
          <li
            key="nextpage"
            className={classNames("page-item", "paginate_button", "next", {
              disabled: activePage === tableProps.pageCount,
            })}
            onClick={() => {
              if (activePage === tableProps.pageCount) return;
              changePage(activePage + 1);
            }}
          >
            <Link to={generateLink()} className="page-link">
              <i className="uil uil-angle-right"></i>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
