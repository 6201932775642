import { APICore } from "./apiCore";

const api = new APICore();

function getDataType(params: { id: number | string }) {
	const baseUrl = "/api/DataTypes/GetDataType";
	return api.get(`${baseUrl}`, params);
}

function getAllDataType(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
}) {
	const baseUrl = "/api/DataTypes/GetAllDataType";
	return api.get(`${baseUrl}`, params);
}

function getAllDataTypeValue(params: { typeName: "Departments" | "Category" | "Reason" | "ManageInspectionType" | "Notification" | "TypeOfOrganization", query?: string; }) {
	const baseUrl = "/api/DataTypes/GetAllDataTypeValue";
	return api.get(`${baseUrl}`, params);
}

function saveDataType(params: any) {
	const baseUrl = "/api/DataTypes/SaveDataType";
	return api.create(`${baseUrl}`, params);
}

function saveDataTypeValue(params: any) {
	const baseUrl = "/api/DataTypes/SaveDataTypeValue";
	return api.create(`${baseUrl}`, params);
}

function deleteDataType(id: string | number) {
	const baseUrl = "/api/DataTypes/DeleteDataType";
	return api.delete(`${baseUrl}?id=${id}`);
}

function deleteDataTypeValue(id: string | number) {
	const baseUrl = "/api/DataTypes/DeleteDataTypeValue";
	return api.delete(`${baseUrl}?id=${id}`);
}

export {
	getDataType,
	getAllDataType,
	getAllDataTypeValue,
	saveDataType,
	saveDataTypeValue,
	deleteDataType,
	deleteDataTypeValue,
};
