import React, { useEffect } from "react";

import AllRoutes from "./routes/Routes";

// import { configureFakeBackend } from "./helpers";

// Themes
// For Default import Theme.scss
import "./assets/scss/Theme.scss";
import { SnackbarProvider } from "notistack";

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
// configureFakeBackend();

const App = () => {
  const SetupOS = () => {
    try {
      console.log("setting up OS");
      if (window.OneSignal) {
        let OneSignal = window.OneSignal || [];
        OneSignal.push(function () {
          OneSignal.init({
            appId: "9a32a5cd-0e5f-4891-8b12-e25bb448e775",
            notifyButton: {
              enable: false,
              size: "small",
            },
            persistNotification: false,
            allowLocalhostAsSecureOrigin: true,
            promptOptions: {
              /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
              /* actionMessage limited to 90 characters */
              actionMessage:
                "We'd like to show you notifications for the latest news and updates.",
              /* acceptButtonText limited to 15 characters */
              acceptButtonText: "ALLOW",
              /* cancelButtonText limited to 15 characters */
              cancelButtonText: "NO THANKS",
            },
          });
        });
      } else {
        console.log("one signal not found in window");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    SetupOS();
  }, []);
  return (
    <>
      <React.Fragment>
        <SnackbarProvider
          classes={{ containerRoot: "z-alert" }}
          autoHideDuration={3500}
        >
          <AllRoutes />
        </SnackbarProvider>
      </React.Fragment>
    </>
  );
};

export default App;
