import { updateAssignementStatus } from "helpers/api/assetType";
import { useToast } from "hooks";
import { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";

interface IApproveClientRequestModal {
  show: boolean;
  setShow: (a: boolean) => void;
  data: any;
  refreshData: any;
}

const ApproveClientRequestModal = ({
  show,
  setShow,
  data = {},
  refreshData = () => {},
}: IApproveClientRequestModal) => {
  const { showToast } = useToast();
  const [requestStatus, setRequestStatus] = useState({
    label: "Approve",
    value: "APPROVED",
  });
  const [progress, setProgress] = useState(false);

  const onConfirm = async () => {
    try {
      setProgress(true);
      const result = await updateAssignementStatus({
        id: data.Id,
        status: requestStatus.value,
      });
      if (result.StatusCode === 200) {
        if (refreshData) refreshData();
        onClose();
        showToast("success", result.Message);
      } else {
        showToast("error", result.Message);
      }
    } catch (error: any) {
      console.log("error", error);
      showToast("error", error.message);
    } finally {
      setProgress(false);
    }
  };

  const onClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header className="pb-0" closeButton>
        <h4>Client Approval</h4>
      </Modal.Header>
      <Modal.Body>
        <Card className="px-0">
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <Card.Img
                className="w-100 obj-fit-contain maxh-100"
                src={data.FullClientProfileUrl}
              />
            </Col>
            <Col md={8}>
              <Card.Body>
                <Card.Text className="m-0">{data.ClientName}</Card.Text>
                <Card.Text>{data.ClientEmail}</Card.Text>
                <Card.Text>Asset Type: {data.AssetTypeName}</Card.Text>
              </Card.Body>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col md={12} className="mb-3">
            <Select
              value={requestStatus}
              options={[
                { label: "Approve", value: "APPROVED" },
                { label: "Reject", value: "REJECT" },
              ]}
              onChange={(selectedOption: any) => {
                setRequestStatus(selectedOption);
              }}
              isDisabled={progress}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="button-list">
            <Button
              variant="primary"
              type="button"
              disabled={progress}
              className="px-2"
              onClick={onConfirm}
            >
              Submit
            </Button>
            <Button
              variant="outline-primary"
              className="px-2"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveClientRequestModal;
