import { APICore } from "./apiCore";

const api = new APICore();

function saveSnippet(params: any) {
  const baseUrl = "/api/Snippet/Save";
  return api.create(`${baseUrl}`, params);
}

function getAllSnippets(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
  userId: number;
  roleName: string;
  roleIds: string;
}) {
  const baseUrl = "/api/Snippet/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getSnippetDetails(params: {
  id?: number | string;
  key?: number | string;
}) {
  const baseUrl = "/api/Snippet/Get";
  return api.get(`${baseUrl}`, params);
}

function deleteSnippet(id: any) {
  const baseUrl = "/api/Snippet/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

export { saveSnippet, getAllSnippets, getSnippetDetails, deleteSnippet };
