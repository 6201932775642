import { APICore } from "./apiCore";

const api = new APICore();

function getAllIntegrationTypes(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
}) {
  const baseUrl = "/api/IntegrationType/GetAll";
  return api.get(`${baseUrl}`, params);
}

function saveIntegrationType(data: any) {
  const baseUrl = "/api/IntegrationType/Save";
  return api.create(`${baseUrl}`, data);
}

export { getAllIntegrationTypes, saveIntegrationType };
