import { createContext, useState, ReactNode } from "react";

interface TimerProviderProps {
  children: ReactNode;
}

const TimerContext = createContext<{
  isVisible: boolean;
  toggleVisibility: (a: boolean) => void;
  currentData: {
    systemObjectId: number;
    systemObjectRecordId: number;
  };
  updateCurrentData: (
    systemObjectId: number,
    systemObjectRecordId: number
  ) => void;
  activeTimerExists: boolean;
  toggleActiveTimerExists: (a: boolean) => void;
  timerActionCBValue: string;
  changeTimerActionCB: (a: string) => void;
}>({
  isVisible: true,
  toggleVisibility: (a: boolean) => {},
  currentData: {
    systemObjectId: 0,
    systemObjectRecordId: 0,
  },
  updateCurrentData: (
    systemObjectId: number,
    systemObjectRecordId: number
  ) => {},
  activeTimerExists: false,
  toggleActiveTimerExists: (a: boolean) => {},
  timerActionCBValue: "",
  changeTimerActionCB: (a: string) => {},
});

const TimerProvider = ({ children }: TimerProviderProps) => {
  const [isVisible, setIsVisible] = useState(false); // State for controlling visibility of timer
  const [activeTimerExists, setActiveTimerExists] = useState(false); // State for controlling visibility of timer
  const [timerActionCBValue, setTimerActionCBValue] = useState(""); // State for controlling visibility of timer
  const [currentData, setCurrentData] = useState({
    systemObjectId: 0,
    systemObjectRecordId: 0,
  });

  const toggleVisibility = (a: boolean) => {
    setIsVisible((prev) => a);
  };

  const toggleActiveTimerExists = (a: boolean) => {
    setActiveTimerExists((prev) => a);
  };

  const changeTimerActionCB = (a: string) => {
    setTimerActionCBValue((prev) => a);
  };

  const updateCurrentData = (systemObjectId = 0, systemObjectRecordId = 0) => {
    setCurrentData((prev) => ({
      systemObjectId: systemObjectId,
      systemObjectRecordId: systemObjectRecordId,
    }));
  };

  return (
    <TimerContext.Provider
      value={{
        isVisible,
        toggleVisibility,
        currentData,
        updateCurrentData,
        activeTimerExists,
        toggleActiveTimerExists,
        timerActionCBValue,
        changeTimerActionCB,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };
