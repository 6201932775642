import { APICore } from "./apiCore";

const api = new APICore();

// vendor
function getVendorList(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
	userIds?: string;
}) {
	const baseUrl = "/api/Vendor/GetAll";
	return api.get(`${baseUrl}`, params);
}

function getVendorDetails(params: { id?: number | string; userId?: number | string }) {
	const baseUrl = "/api/Vendor/Get";
	return api.get(`${baseUrl}`, params);
}

function saveVendorDetails(params: any) {
	const baseUrl = "/api/Vendor/Save";
	return api.create(`${baseUrl}`, params);
}

function deleteVendorDetails(id: string | number) {
	const baseUrl = "/api/Vendor/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

function signupVendor(params: any) {
	const baseUrl = "/api/Vendor/SignUp";
	return api.create(`${baseUrl}`, params);
}

function inviteVendor(params: any) {
	const baseUrl = "/api/Vendor/InviteVendor";
	return api.create(`${baseUrl}?id=${params.id}`,{});
};

function verifyVendor(params: any) {
	const baseUrl = "/api/Vendor/VendorVerify";
	return api.create(`${baseUrl}?vendorId=${params.vendorId}&status=${params.status}`, {});
}

function saveVendorService(data: any) {
	const baseUrl = "/api/Vendor/SaveVendorService";
	return api.create(`${baseUrl}`, data);
}

function deleteVendorService(id: string | number) {
	const baseUrl = "/api/Vendor/DeleteVendorService";
	return api.delete(`${baseUrl}?id=${id}`);
}

export {
  getVendorList,
  getVendorDetails,
  saveVendorDetails,
  deleteVendorDetails,
  signupVendor,
  verifyVendor,
  inviteVendor,
  saveVendorService,
  deleteVendorService
};
