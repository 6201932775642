import Logo from "../../assets/images/linkedLogo.svg";

const QRLanding = () => {
  return (
    <>
      <div className='text-center'>
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ height: "100vh" }}
        >
          <div className='auth-logo mx-auto'>
            <div className='logo text-center'>
              <span>
                <img src={Logo} alt='' height='125' />
              </span>
            </div>
          </div>

          <h4 className='h4 mb-0 mt-1'>Property of TRIAD</h4>
          <p className='text-muted mt-1 mb-4'>
            Please contact <a href='tel:(314)312-3399'>(314)312-3399</a> or
            Email us at
            <br /> <a href='mailto:info@360linked.com'>
              info@360linked.com
            </a>{" "}
            for more information.
          </p>
        </div>
      </div>
    </>
  );
};

export default QRLanding;
