import { IVendorDetails } from './interfaces';

export const venderTableColumns: {Header: string, accessor: keyof IVendorDetails | "" } [] = [
	{
		Header: "Company Name",
		accessor: "CompanyName",
	},
    {
        Header: "Main Contact Person",
        accessor: "MainContact",
    },
    {
        Header: "Title",
        accessor: "Title",
    },
    {
        Header: "City",
        accessor: "CityName",
    },
    {
        Header: "Email",
        accessor: "ContactEmail",
    },
    {
        Header: "Main Phone",
        accessor: "MainPhone",
    },
    {
        Header: "Type of Services",
        accessor: "TypeOfService",
    },
    {
        Header: "Website",
        accessor: "",
    },
    {
        Header: "Contact Phone",
        accessor: "ContactPhone",
    },
    {
        Header: "Assets Serving",
        accessor: "",
    },
    {
        Header: "No. of Businesses",
        accessor: "",
    },
    {
        Header: "No. of Clients",
        accessor: "",
    },
    {
        Header: "No. of Equipment",
        accessor: "",
    },
    {
        Header: "No. of Fleets",
        accessor: "",
    }, 
    {
        Header: "No. of Location",
        accessor: "",
    }
];