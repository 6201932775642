import { ISelectOption, UserRole } from "interfaces";
import Logo from "assets/images/linkedLogo.svg"
export * from "./layout";

interface IUSERROLE {
    SUPERADMIN: UserRole,
    ADMIN: UserRole,
    USER: UserRole,
    VENDOR: UserRole,
	INDEPENDENT_VENDOR: UserRole,
	INDEPENDENT_USER: UserRole,
}

export const USER_ROLE: IUSERROLE = {
    SUPERADMIN: 'SuperAdmin',
    ADMIN: 'Admin',
    VENDOR: 'Vendor',
    USER: 'User',
	INDEPENDENT_VENDOR: 'Independent-Vendor',
	INDEPENDENT_USER: 'Independent-User',
}

export const USER_ROLE_ID = {
    SUPERADMIN: 1,
    ADMIN: 2,
    VENDOR: 3,
    USER: 4
}

export const UserRoles = [
    // {
	// 	Id: 1,
	// 	label: USER_ROLE.SUPERADMIN,
	// },
	// {
	// 	Id: 2,
	// 	label: USER_ROLE.ADMIN,
	// },
    // {
	// 	Id: 3,
	// 	label: USER_ROLE.VENDOR,
	// },
    {
        Id: 4,
        label: USER_ROLE.USER
    }
];

export const GoogleMapKey = process.env.REACT_APP_GOOGL_MAP_KEY;

export const DefaultBrandLogo = Logo;

export const DurationOptions: ISelectOption[] = [
	{
		value: 30,
		label: "30 mins",
	},
	{
		value: 45,
		label: "45 mins",
	},
	{
		value: 60,
		label: "1 hour",
	},
	{
		value: 90,
		label: "1:30 hours",
	},
];

export const VendorStatusOptions = [
	{
		value: '',
		label: 'All'
	},
	{
		value: 'verified',
		label: 'Verified'
	},
	{
		value: 'nonVerified',
		label: 'Non Verified'
	}
]

export const VendorStatuses = [
	{
		value: 'approved',
		label: 'Approve'
	},
	{
		value: 'reject',
		label: 'Reject'
	},
]

export const Units = [
	{ label: "mm", value: "mm"},
	{ label: "in", value: "in"},
	{ label: "cm", value: "cm"},
	{ label: "m", value: "m"},
	{ label: "km", value: "km"},
	{ label: "mg", value: "mg"},
	{ label: "cg", value: "cg"},
	{ label: "g", value: "g"},
	{ label: "kg", value: "kg"},
	{ label: "t", value: "t"},
	{ label: "sq.cm", value: "sq.cm"},
	{ label: "sq.m", value: "sq.m"},
	{ label: "sq.km", value: "sq.km"},
	{ label: "ha", value: "ha"},
	{ label: "mi", value: "mi"},
	{ label: "cl", value: "cl"},
	{ label: "kl", value: "kl"},
	{ label: "sq.mi", value: "sq.mi"},
	{ label: "ft", value: "ft"},
	{ label: "p", value: "p"},
	{ label: "oz", value: "oz"},
	{ label: "mi", value: "mi"},
	{ label: "yd", value: "yd"},
]

export const DurationUnits = [
	{ label: "Days", value: "Days"},
	{ label: "Hours", value: "Hours"},
	{ label: "Minutes", value: "Minutes"},
	{ label: "Weeks", value: "Weeks"},
	{ label: "Months", value: "Months"},
]