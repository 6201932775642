import { InputCtrl } from "controllers";
import { useForm } from "react-hook-form";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useToast } from "hooks";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/linkedLogo.svg";
import TermsConditionsPolicyText from "components/TermsConditionsPolicyText";
import { ClientSignUp } from "helpers/api/user";

const SignUp = () => {
  const [inProgress, setInProgress] = useState(false);
  const { showToast, dissmisToast } = useToast();
  const navigate = useNavigate();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const defaultValues: any = {
    FirstName: "",
    LastName: "",
    Password: "",
    CompanyName: "",
    Email: "",
  };

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    watch,
    setValue,
  } = useForm<any>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  const onSubmit = async (data: any) => {
    if (!termsAccepted) {
      showToast("error", "Please accept terms and conditions to continue");
      return;
    }
    if (data.password !== data.Password) {
      showToast("error", "Password does not match");
      return;
    }
    const key = "ClientSignUp";
    try {
      setInProgress(true);
      showToast("info", "Your request is being processed", key);
      const {Data} = await ClientSignUp({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Password: data.Password,
        CompanyName: data.CompanyName,
        Email: data.Email,
      });
      dissmisToast(key);
      if (Data?.StatusCode === 200) {
        showToast("success", Data.Message);
        navigate("/verify-otp", {
          state: { email: data.Email, otp: Data?.Data?.OTP, token: Data?.Data?.Token.toString()},
        });
      } else {
        showToast("error", Data.Message);
      }
      setInProgress(false);
    } catch (error: any) {
      dissmisToast(key);
      console.error("Error occurred:", error);
      showToast("error", error.message);
      setInProgress(false);
    }
  };

  return (
    <>
      <div className="text-center d-flex align-items-center justify-content-center mt-5 ">
        <Card>
          <div className="d-flex flex-column align-items-center justify-content-center p-2 shadow rounded">
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-lg">
                <img src={Logo} alt="" height="100" />
              </span>
            </Link>
            <div className="mt-1 mb-2 text-black" style={{ fontSize: "18px" }}>
              360Linked is not just ERP - it's your business partner.
            </div>
            <div className=" mb-3 text-black" style={{ fontSize: "14px" }}>
              Wheter you are a city authority, a busting business or a
              mission-driven non profit solutions molded to your unique needs,
              with AI as its code.
            </div>

            <form
              className="w-50 text-start"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Card.Body>
                <Row>
                  <Col xl={12}>
                    <Row>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="FirstName"
                          id="FirstName"
                          placeholder="First Name"
                          label="First Name"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="FullName"
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="LastName"
                          id="LastName"
                          placeholder="Last Name"
                          label="Last Name"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="FullName"
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="CompanyName"
                          id="CompanyName"
                          placeholder="Company Name"
                          label="Company Name"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="FullName"
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="text"
                          name="Email"
                          id="Email"
                          placeholder="Email address"
                          label="Email address"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="Email"
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Enter Password"
                          label="Password"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="FullName"
                        />
                      </Col>
                      <Col xl={6} md={6}>
                        <InputCtrl
                          control={control}
                          type="password"
                          name="Password"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          label="Confirm Password"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          componentName="FullName"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
              <TermsConditionsPolicyText
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
              />
              <div className="d-flex justify-content-center align-items-center mt-2">
                <div className="button-list">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={inProgress}
                    className="rounded-pill px-4 py-2"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </div>
    </>
  );
};

export default SignUp;
