import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Vendor from "./vendor/reducers";
import Team from "./team/reducer";
import Asset from "./assets/reducers";
import MenuItems from "./menuItems/reducers";


export default combineReducers({
  Auth,
  Layout,
  Vendor,
  Team,
  Asset,
  MenuItems
});
