import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { GoogleMapKey } from "constants/index";

interface ILatLng {
	lat: number;
	lng: number;
}

interface IMarker {
	name: string;
	placeId: string;
	position: ILatLng;
}

interface MapContainerProps {
	google: any;
	onChangePlaceId: (placeId: string, latLan: ILatLng) => void;
	onInputChange: (val: string) => void;
	marker: IMarker;
	autocompleteId: string;
	autocompleteRef: any;
}

const options = {
	fields: ["place_id", "formatted_address", "geometry", "name"],
	strictBounds: false,
};

const GoogleMaps = ({
	google,
	onChangePlaceId,
	marker,
	autocompleteRef,
	onInputChange = () => null,
}: MapContainerProps) => {
	const googleMapRef = useRef<any>(null); // map object
	const autocompleteId = "autocomplete";
	const [currentMarker, setCurrentMarker] = useState<IMarker>();
	const mapClicked = (mapProps: any, map: any, coords: any) => {
		const { latLng } = coords;
		const lat = latLng?.lat() || 0;
		const lng = latLng?.lng() || 0;
		if (!currentMarker) {
			getUpdateAddress({ lat: lat, lng: lng }, { isClickOnMap: true });
		}
	};
	const fetchPlaces = (mapProps: any, map: any) => {
		if (autocompleteId && document.getElementById(autocompleteId)) {
			console.log(
				autocompleteId,
				document.getElementById(autocompleteId),
			);
			const autocomplete = new google.maps.places.Autocomplete(
				document.getElementById(autocompleteId),
				options,
			);
			autocomplete.bindTo("bounds", map);
			autocomplete.addListener("place_changed", () => {
				var place = autocomplete.getPlace();
				if (!place.geometry || !place.geometry.location) {
					// User entered the name of a Place that was not suggested and
					// pressed the Enter key, or the Place Details request failed.
					alert(
						"No details available for input: '" + place.name + "'",
					);
					return;
				}
				const location = place.geometry.location;
				getUpdateAddress(
					{ lat: location.lat(), lng: location.lng() },
					{ isAutocomplete: true, name: place.name },
				);
			});
		}
	};

	const mapFocuse = (latLng: ILatLng) => {
		const map = googleMapRef.current?.map;
		if (map) {
			const center = new google.maps.LatLng(latLng.lat, latLng.lng);
			map.panTo(center);
			map.setZoom(17);
		}
	};

	const getPlace = (placeId: string) => {
		const map = googleMapRef.current?.map;
		const service = new google.maps.places.PlacesService(map);
		const request = {
			placeId: placeId,
			...options,
		};
		service.getDetails(request, (place: any, status: any) => {
			if (
				status === google.maps.places.PlacesServiceStatus.OK &&
				place &&
				autocompleteRef?.current
			) {
				autocompleteRef.current.value = place.formatted_address;
				onInputChange(place.formatted_address);
			}
		});
	};

	const getPlaceId = (latlng: ILatLng, cb: (placeId: string) => void) => {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ location: latlng }, (results: any, status: any) => {
			if (status === google.maps.GeocoderStatus.OK) {
				if (results[1]) {
					if (results[1].place_id) {
						cb(results[1].place_id);
					}
				} else {
					alert("No results found");
				}
			} else {
				alert("Geocoder failed due to: " + status);
			}
		});
	};

	const getUpdateAddress = (
		latlng: ILatLng,
		options?: {
			isAutocomplete?: boolean;
			isDraggable?: boolean;
			isClickOnMap?: boolean;
			name?: string;
		},
	) => {
		const geocoder = new google.maps.Geocoder();
		const map = googleMapRef.current?.map;
		const {
			isAutocomplete = false,
			isDraggable = false,
			isClickOnMap = false,
			name = "",
		} = options || {};
		geocoder.geocode({ location: latlng }, (results: any, status: any) => {
			if (status === google.maps.GeocoderStatus.OK) {
				if (results[1]) {
					if (results[1].place_id && onChangePlaceId) {
						onChangePlaceId(results[1].place_id, latlng);
						getPlace(results[1].place_id);
					}
					if (isAutocomplete || isClickOnMap) {
						setCurrentMarker({
							placeId: results[1].place_id,
							name: name || "",
							position: latlng,
						});
					}
					if (map && !isDraggable) {
						mapFocuse({ lat: latlng.lat, lng: latlng.lng });
					}
				} else {
					alert("No results found");
				}
			} else {
				alert("Geocoder failed due to: " + status);
			}
		});
	};

	const onDragEnd = (mapProps: any, map: any, event: any) => {
		const { latLng } = event;
		const lat = latLng?.lat() || 0;
		const lng = latLng?.lng() || 0;
		const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
		getUpdateAddress(latlng, { isDraggable: true });
	};

	useEffect(() => {
		if (
			!currentMarker &&
			marker &&
			marker.position &&
			(marker.position.lat || marker.position.lng)
		) {
			setCurrentMarker({
				placeId: marker.placeId || "",
				name: marker.name || "",
				position: marker.position,
			});
			if (marker.placeId) {
				getPlace(marker.placeId);
			} else if (
				marker.position &&
				(marker.position.lat || marker.position.lng)
			) {
				mapFocuse({
					lat: marker.position.lat,
					lng: marker.position.lng,
				});
				getPlaceId(
					{ lat: marker.position.lat, lng: marker.position.lng },
					(placeId: string) => {
						getPlace(placeId);
					},
				);
			}
			mapFocuse({ lat: marker.position.lat, lng: marker.position.lng });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marker]);
	return (
		<React.Fragment>
			<Row className="mb-2">
				<Col lg={12}>
					{/* <Form.Group className="mb-2">
						<Form.Label htmlFor={autocompleteId}>Location</Form.Label>
						<Form.Control
							type="text"
							name="autocomplete"
							id={autocompleteId}
							placeholder="Enter Location to auto search 3"
							defaultValue=""
							ref={autocompleteRef}
							required={true}
						/>
					</Form.Group> */}
					<div
						id="gmaps-basic"
						style={{ position: "relative" }}
						className="gmaps"
					>
						<Map
							google={google}
							zoom={14}
							style={{
								width: "100%",
								height: "100%",
								position: "relative",
							}}
							zoomControlOptions={{
								position: google.maps.ControlPosition.LEFT_TOP,
							}}
							onClick={mapClicked}
							onReady={fetchPlaces}
							ref={googleMapRef}
						>
							{currentMarker && (
								<Marker
									name={currentMarker.name}
									draggable={true}
									onDragend={onDragEnd}
									position={currentMarker.position}
								/>
							)}
						</Map>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default GoogleApiWrapper({
	apiKey: GoogleMapKey,
})(GoogleMaps);
