import React from "react";
import SimpleBar from "simplebar-react";

interface ScrollbarProps extends SimpleBar.Props {
  className?: string;
  style?: any;
  children?: any;
  customRef?: any;
}

const Scrollbar = ({
  className,
  style,
  children,
  customRef,
  ...otherProps
}: ScrollbarProps) => {
  return (
    <SimpleBar className={className} style={style} ref={customRef} {...otherProps}>
      {children}
    </SimpleBar>
  );
};

export default Scrollbar;
