// @ts-ignore
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { useApi, useToast, useUploadFile } from "hooks";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { getExtensionFromURL } from "utils";
import DeleteConfirm from "components/InplaceConfirm/DeleteConfirm";
import {
  IMAGE_EXT,
  OpenLink,
  PERMISSIONS_KEY,
  ReadLocalStorage,
  VIDEO_EXT,
} from "common/utility";
import Select from "react-select";
import { getRoles } from "helpers/api/role";

interface IImageGrid {
  files: any[];
  setFiles: any;
  labelText?: string;
  acceptFiles?: string;
  showUploader?: boolean;
  hideRemoveIcon?: boolean;
  CustomNoData?: any;
  imageWrapClass?: any;
  showMax?: any;
  customOnClick?: any;
  filesChangedCB?: any;
  syncSaveInProgress?: boolean;
}

const defaultValues: {
  Caption: string;
  FullFileUrl: string;
  Files: any;
  Type: string;
  Name: string;
} = {
  Caption: "",
  FullFileUrl: "",
  Files: null,
  Type: "",
  Name: "",
};

const multiple = true;

const ImageGrid = ({
  files,
  setFiles,
  labelText = "Upload Files",
  showUploader = true,
  hideRemoveIcon = false,
  acceptFiles = "image/*, video/*",
  CustomNoData = undefined,
  imageWrapClass = "",
  showMax = undefined,
  customOnClick = undefined,
  filesChangedCB = undefined,
  syncSaveInProgress = false,
}: IImageGrid) => {
  const [activeThumbnail, setActiveThumbnail] = useState(0);
  const [roleSelectEnabled, setRoleSelectEnabled] = useState(false);
  const [uploadInfo, setUploadInfo] = useState({
    doneCount: 0,
    totalCount: 0,
    currentDonePercent: "0%",
  });
  const [show, setShow] = useState(false);
  const [showLightBox, setShowLightBox] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [caption, setCaption] = useState("");
  const { showToast, dissmisToast } = useToast();
  const fileInputRef: any = useRef(null);
  const [filesData, setFilesData] = useState({
    ...defaultValues,
  });
  const [additionalData, setAdditionalData] = useState<any[]>([]);
  const onClose = () => {
    setShow(false);
    setFilesData({ ...defaultValues });
    setAdditionalData([]);
    setUploadInfo({
      doneCount: 0,
      totalCount: 0,
      currentDonePercent: "0%",
    });
  };
  const HandleSave = async () => {
    let arr: any[] = [];
    additionalData.forEach((item) => {
      arr.push({
        caption: item.Caption,
        roleIds: item.RoleIds
          ? item.RoleIds.map((item: any) => item.value).join(",")
          : "",
      });
    });

    multiUploadFile(filesData.Files, arr);
  };
  const uploadFile = () => {
    if (fileInputRef) {
      fileInputRef?.current?.click();
    }
  };
  const handleFileChange = (_files: any) => {
    const url = URL.createObjectURL(_files[0]);
    setFilesData((prev) => {
      return {
        ...prev,
        FullFileUrl: url,
        Files: _files,
        Type: _files[0].type,
        Name: _files[0].name,
      };
    });
    const arr = [];
    for (let i = 0; i < _files.length; i++) {
      arr.push({
        Caption: "",
        RoleIds: null,
      });
    }
    setAdditionalData([...arr]);
  };
  const handleChange = (evt: any) => {
    try {
      let { value } = evt.target;
      const copy = [...additionalData];
      copy[activeThumbnail] = {
        ...copy[activeThumbnail],
        Caption: value,
      };
      setAdditionalData([...copy]);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleRolesChange = (selectedOption: any) => {
    try {
      const copy = [...additionalData];
      copy[activeThumbnail] = {
        ...copy[activeThumbnail],
        RoleIds: selectedOption,
      };
      setAdditionalData([...copy]);
    } catch (error) {
      console.log("error", error);
    }
  };
  const { uploading: isUploading, multiUploadFile } = useUploadFile({
    onFire: () => {
      if (!multiple) {
        // showToast("info", "Uploading 1%", "UploadImage");
      }
    },
    onPercentage: (
      val: number,
      totalUploaded: number,
      totalFilesCount: number
    ) => {
      if (!multiple) {
        // showToast("info", "Uploading 1%", "UploadImage");
      }
      // showToast(
      //   "info",
      //   `Uploading ${
      //     multiple ? `${totalUploaded}/${totalFilesCount} ` : ""
      //   }${val}%`,
      //   "UploadImage"
      // );
      setUploadInfo((prev) => ({
        ...prev,
        doneCount: totalUploaded,
        totalCount: totalFilesCount,
        currentDonePercent: `${val}%`,
      }));
    },
    onSuccess: (fileData: any) => {
      if (setFiles) {
        setFiles((prevState: any[]) => {
          if (filesChangedCB) {
            filesChangedCB([...prevState, fileData]);
          }
          return [...prevState, fileData];
        });
      }
    },
    onCompleted: (results: Array<any>) => {
      dissmisToast("UploadImage");
      onClose();
    },
    onError: (error: any) => {
      dissmisToast("UploadImage");
      showToast("error", error.message ? error.message : error);
    },
  });
  const resolveImage = (fileUrlName: any) => {
    try {
      const ext = getExtensionFromURL(fileUrlName);
      let extension = ext.toUpperCase();
      if (
        extension === "JPG" ||
        extension === "JPEG" ||
        extension === "PNG" ||
        extension === "SVG"
      ) {
        return false;
      } else {
        return extension;
      }
    } catch (error) {
      console.log("error", error);
    }
    return "";
  };
  const removeImage = (index: any) => {
    try {
      files.splice(index, 1);
      setFiles([...files]);
      if (filesChangedCB) {
        filesChangedCB([...files]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const IsVideoOrImage = (fileUrl: any) => {
    return (
      IMAGE_EXT.concat(VIDEO_EXT).indexOf(getExtensionFromURL(fileUrl)) !== -1
    );
  };
  const HandleClick = (file: any, index: any) => {
    try {
      const _files = files
        .filter((item) => IsVideoOrImage(item.FullFileUrl))
        .map((file: any) => ({
          ...file,
        }));
      let foundIdx = _files.findIndex((item: any) => {
        return item.FullFileUrl === files[index].FullFileUrl;
      });
      if (foundIdx !== -1) {
        setShowLightBox(true);
        setCurrentIndex(foundIdx);
        setCaption(
          _files[foundIdx].ItemName || setCaption(_files[foundIdx].Caption)
        );
      } else {
        OpenLink(file.FullFileUrl);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const SetupPermission = () => {
    try {
      const permissions: any = ReadLocalStorage(PERMISSIONS_KEY);
      if (permissions && permissions.data && permissions.data.Permissions) {
        const found: any = permissions.data.Permissions.find(
          (el: any) => el.PermissionKey === "Files.View"
        );
        if (found) {
          setRoleSelectEnabled(found.HasPermission);
        }
      }
      return false;
    } catch (error) {
      console.log("error", error);
    }
  };
  const { list: roles = [] } = useApi(
    "GetAllRoles",
    () =>
      getRoles({
        pageNumber: 1,
        pageSize: 20,
        query: "all",
        filters: "SUB_ROLES",
      }),
    {
      enabled: true,
      onSuccess: (apiRes) => apiRes.Data,
    }
  );
  const ActiveThumbnailProperty = (actIdx: any, propertyName: string = "") => {
    try {
      const _f: any = Array.from(filesData.Files)[actIdx];
      if (propertyName === "fileUrl") {
        return URL.createObjectURL(_f);
      }
      return _f[propertyName];
    } catch (error) {
      console.log("error", error);
    }
    return "";
  };
  useEffect(() => {
    SetupPermission();
  }, []);
  return (
    <div className="image-grid-view pt-1">
      <Modal show={show} onHide={onClose} size="lg" centered>
        <Modal.Header closeButton>
          <h5 className="modal-title">{labelText}</h5>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            {filesData.Files &&
              filesData.Files.length &&
              Array.from(filesData.Files).map((f: any, index: number) => (
                <Col
                  md={1}
                  className={`preview-thumbnail d-flex align-items-center justify-content-center cursor-pointer p-2 me-2 rounded ${
                    activeThumbnail === index ? "active" : ""
                  }`}
                  onClick={() => setActiveThumbnail(index)}
                >
                  <img
                    src={
                      f.type.includes("image/")
                        ? URL.createObjectURL(f)
                        : `fileExtensionsSVGs/${resolveImage(f.name)}.svg`
                    }
                    alt="FullFileUrl"
                    className="img-fluid"
                  />
                </Col>
              ))}
          </Row>
          <form name="caption-form" id="caption-form">
            <Row>
              <Col md={12}>
                <span className="position-absolute top-0 end-0 pt-1 pe-1 upload-icon">
                  {!isUploading && (
                    <>
                      <div>
                        <input
                          ref={fileInputRef}
                          type="file"
                          multiple={multiple}
                          style={{ display: "none" }}
                          disabled={isUploading}
                          id={"manageUserProfile"}
                          accept={acceptFiles}
                          onChange={(e) => {
                            const _files = e.target.files;
                            if (_files?.length) {
                              handleFileChange(_files);
                            }
                          }}
                        />
                        <div>
                          <i
                            onClick={() => fileInputRef?.current?.click()}
                            className="bi bi-upload h3 cursor-pointer me-2"
                          ></i>
                        </div>
                      </div>
                    </>
                  )}
                </span>
                {ActiveThumbnailProperty(activeThumbnail, "fileUrl") ? (
                  <>
                    {ActiveThumbnailProperty(activeThumbnail, "type").includes(
                      "video/"
                    ) ? (
                      <video width="100%" height="300" controls>
                        <source
                          src={ActiveThumbnailProperty(
                            activeThumbnail,
                            "fileUrl"
                          )}
                          type={`video/${getExtensionFromURL(
                            ActiveThumbnailProperty(activeThumbnail, "name")
                          )}`}
                        />
                        Your browser does not support HTML video.
                      </video>
                    ) : ActiveThumbnailProperty(
                        activeThumbnail,
                        "type"
                      ).includes("image/") ? (
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={ActiveThumbnailProperty(
                            activeThumbnail,
                            "fileUrl"
                          )}
                          alt="FullFileUrl"
                          className="img-fluid mb-3"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          onClick={uploadFile}
                        />
                      </div>
                    ) : (
                      <div className="image-grid-view">
                        <div className="item-outer position-relative mb-2 me-3">
                          <div className="grid-img-wrapper d-flex flex-column align-items-center">
                            <div className="img-wrapper cursor-pointer text-center border-0">
                              <img
                                src={`fileExtensionsSVGs/${resolveImage(
                                  ActiveThumbnailProperty(
                                    activeThumbnail,
                                    "name"
                                  )
                                )}.svg`}
                                alt="attachment"
                                className="img-thumbnail img-responsive bg-transparent w-100 border-0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isUploading && (
                      <div className="upload-percent text-muted text-end">
                        {uploadInfo.currentDonePercent}
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="mb-3 d-flex align-items-center justify-content-center fs-20 cursor-pointer"
                    style={{
                      minHeight: 150,
                      border: "2px dashed gray",
                    }}
                    onClick={uploadFile}
                  >
                    {labelText}
                  </div>
                )}
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={"caption"}>Caption</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    id="caption"
                    name="Caption"
                    value={
                      filesData.Files
                        ? additionalData[activeThumbnail].Caption
                        : ""
                    }
                    onChange={handleChange}
                    disabled={isUploading}
                    placeholder={"Caption"}
                  />
                </Form.Group>
              </Col>
              {roleSelectEnabled && (
                <Col md={12} className="mb-3">
                  <Select
                    placeholder="Select Role"
                    value={
                      filesData.Files
                        ? additionalData[activeThumbnail].RoleIds
                        : null
                    }
                    onChange={(selectedOption) => {
                      handleRolesChange(selectedOption);
                    }}
                    options={roles.map((el: any) => ({
                      label: el.DisplayName,
                      value: el.Id,
                    }))}
                    isMulti
                  />
                </Col>
              )}
            </Row>
            <div className="d-flex justify-content-between align-items-baseline">
              <div className="button-list">
                <Button
                  variant="primary"
                  type="button"
                  disabled={isUploading}
                  className="px-2"
                  onClick={HandleSave}
                >
                  {isUploading && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </Button>
                <Button
                  variant="outline-primary"
                  className="px-2"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {files.length === 0 && !showUploader ? (
        CustomNoData ? (
          CustomNoData()
        ) : (
          <div className="text-center">No Images</div>
        )
      ) : null}
      <div className={`d-flex flex-wrap ${imageWrapClass}`}>
        {files
          .filter((v, i) => {
            if (showMax) {
              return i < showMax;
            }
            return true;
          })
          .map((file: any, index: number) => {
            return (
              <div className="item-outer position-relative mb-2 me-1">
                <div
                  className="grid-img-wrapper d-flex flex-column align-items-center"
                  onClick={() => {
                    if (customOnClick) {
                      customOnClick(file);
                    } else {
                      HandleClick(file, index);
                    }
                  }}
                >
                  <div
                    className={`img-wrapper cursor-pointer text-center ${
                      !resolveImage(file.FullFileUrl) ? "border-0" : "p-2"
                    }`}
                  >
                    <img
                      src={
                        !resolveImage(file.FullFileUrl)
                          ? file.FullFileUrl
                          : file.Type === "video" ||
                            resolveImage(file.FullFileUrl) === "PDF"
                          ? file.FullThumbFileUrl
                          : `fileExtensionsSVGs/${resolveImage(
                              file.FullFileUrl
                            )}.svg`
                      }
                      alt="attachment"
                      className={`img-thumbnail img-responsive bg-transparent w-100 border-0 ${
                        !resolveImage(file.FullFileUrl) ? "p-0" : ""
                      }`}
                      onError={(e: any) => {
                        if (
                          (file.Type === "video" ||
                            resolveImage(file.FullFileUrl) === "PDF") &&
                          e.target.src
                        ) {
                          e.target.src = `fileExtensionsSVGs/${resolveImage(
                            file.FullFileUrl
                          )}.svg`;
                        }
                      }}
                    />
                  </div>
                  <span className="img-caption cursor-pointer">
                    {file.ItemName || file.Caption}
                  </span>
                </div>
                <span
                  className={`img-remove-icon ${
                    hideRemoveIcon || syncSaveInProgress ? "d-none" : ""
                  }`}
                >
                  <DeleteConfirm
                    isHide={false}
                    disabled={false}
                    iconColor="text-blue"
                    iconMargin=""
                    iconSize="fs-18"
                    iconClass="uil uil-times"
                    confirm={() => {
                      removeImage(index);
                    }}
                  />
                </span>
                {showMax &&
                index + 1 === showMax &&
                showMax !== files.length ? (
                  <div
                    className="rounded max-images-overlay cursor-pointer"
                    onClick={() => {
                      if (customOnClick) {
                        customOnClick(file);
                      } else {
                        HandleClick(file, index);
                      }
                    }}
                  >
                    +<span>{files.length - showMax}</span>
                  </div>
                ) : null}
              </div>
            );
          })}
        <div
          className={`img-wrapper cursor-pointer text-center ${
            !showUploader ? "d-none" : ""
          }`}
        >
          <div
            className="upload-btn d-flex justify-content-center align-items-center cursor-pointer"
            onClick={() => {
              if (syncSaveInProgress) return;
              setShow(true);
            }}
          >
            <i className="bi bi-upload h3 cursor-pointer" />
          </div>
          <p className="img-caption cursor-pointer mt-1">Upload</p>
        </div>
      </div>
      {showLightBox ? (
        <div className="light-box-wrapper">
          <ReactImageVideoLightbox
            data={files
              .filter((item) => IsVideoOrImage(item.FullFileUrl))
              .map((file: any) => ({
                url: file.FullFileUrl,
                type:
                  VIDEO_EXT.indexOf(getExtensionFromURL(file.FullFileUrl)) !==
                  -1
                    ? "video"
                    : "photo",
                altTag: "attachments",
              }))}
            startIndex={currentIndex}
            showResourceCount={true}
            onCloseCallback={() => {
              setShowLightBox(false);
            }}
            onNavigationCallback={(curIndex: number) => {
              if ((curIndex || curIndex === 0) && files[curIndex]) {
                setCaption(
                  files[curIndex].ItemName ||
                    setCaption(files[curIndex].Caption)
                );
              }
            }}
          />
          <div className="caption-section d-flex justify-content-center p-3">
            <div className="w-50 text-center">{caption}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageGrid;
