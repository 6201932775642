const groupByFields = (array: Array<any>, f: any): Array<any> => {
	/*
    params description :
        f : function which returnf the array of fields 
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]       
    */
	var groups: { [key: string]: any[] } = {};
	array.forEach((o) => {
		var group = JSON.stringify(f(o));
		groups[group] = groups[group] || [];
		groups[group].push(o);
	});

	return Object.keys(groups).map((group) => {
		return groups[group];
	});
};

/**
 * split array into chunks
 * @param array - array to split
 * @param chunkSize - chunk size
 * @returns
 */
const splitArray = (array: Array<any>, chunkSize: number) => {
	const chunks = Array(Math.ceil(array.length / chunkSize))
		.fill(1)
		.map((_, index) => index * chunkSize)
		.map((begin) => array.slice(begin, begin + chunkSize));
	return chunks;
};

const randomElement = (array: Array<any>) =>
	array[Math.floor(Math.random() * array.length)];

const removeDuplicates = (array: Array<any>, key: string) =>
	array.filter(
		(item, index) =>
			array.findIndex((obj) => obj[key] === item[key]) === index,
	);

type GroupedData<T> = { [key: string]: T[] };

const groupBy = <T>(arr: T[], key: keyof T): GroupedData<T> =>
	arr.reduce((result: GroupedData<T>, item: T) => {
		const groupKey = item[key] as unknown as string;
		result[groupKey] = [...(result[groupKey] || []), item];
		return result;
	}, {});

/**
 * Groups an array of objects by a specified key.
 *
 * @param {T[]} arr - The array to be grouped.
 * @param {keyof T} key - The key to group the objects by.
 * @return {GroupedData<T>} An array of objects grouped by the specified key.
 */

const groupByKey = <T>(arr: T[], key: keyof T): GroupedData<T> =>
	arr.reduce((acc: any, d: any) => {
		const found = acc.find((a: any) => a[key] === d[key]);
		const value = { ...d }; // the element in data property
		if (!found) {
			acc.push({ [key]: d[key], Items: [value] }); // not found, so need to add data property
		} else {
			found.Items.push(value); // if found, that means data property exists, so just push new element to found.data.
		}
		return acc;
	}, []);

export { groupByFields, splitArray, randomElement, removeDuplicates, groupBy, groupByKey };
