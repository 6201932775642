import { PageTitleBox } from "components";
import AppLoader from "components/AppLoader";
import { Card } from "react-bootstrap";
import {
  ASSET_SEARCH_COLUMNS,
  ASSET_TYPE_SEARCH_COLUMNS,
  CHECK_IN_OUT_SEARCH_COLUMNS,
  FLOOR_SEARCH_COLUMNS,
  GROUP_SEARCH_COLUMNS,
  INSPECTION_SEARCH_COLUMNS,
  LOCATION_SEARCH_COLUMNS,
  MAINTENANCE_SEARCH_COLUMNS,
  RESPONSE_REQUEST_SEARCH_COLUMNS,
  TICKET_WO_SEARCH_COLUMNS,
  USER_SEARCH_COLUMNS,
  VENDOR_SEARCH_COLUMNS,
} from "./constants";
import ImageGrid from "components/ImageGrid/ImageGrid";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { GlobalSearch } from "helpers/api/dashboard";
import classNames from "classnames";

const ResultTables = [
  { key: "Users", title: "Users", columns: USER_SEARCH_COLUMNS },
  { key: "Assets", title: "Assets", columns: ASSET_SEARCH_COLUMNS },
  {
    key: "AssetTypes",
    title: "Asset Types",
    columns: ASSET_TYPE_SEARCH_COLUMNS,
  },
  { key: "Floors", title: "Floors", columns: FLOOR_SEARCH_COLUMNS },
  {
    key: "Locations",
    title: "Buildings/ Locations",
    columns: LOCATION_SEARCH_COLUMNS,
  },
  {
    key: "ResponseRequests",
    title: "Response Requests",
    columns: RESPONSE_REQUEST_SEARCH_COLUMNS,
  },
  {
    key: "Check in/out",
    title: "Check In/ Out",
    columns: CHECK_IN_OUT_SEARCH_COLUMNS,
  },
  {
    key: "Inspections",
    title: "Inspections",
    columns: INSPECTION_SEARCH_COLUMNS,
  },
  { key: "TICKET", title: "Tickets/ WOs", columns: TICKET_WO_SEARCH_COLUMNS },
  {
    key: "MaintenanceSchedule",
    title: "Maintenance",
    columns: MAINTENANCE_SEARCH_COLUMNS,
  },
  { key: "Vendors", title: "Vendors", columns: VENDOR_SEARCH_COLUMNS },
  { key: "Groups", title: "Teams", columns: GROUP_SEARCH_COLUMNS },
];

const SearchResults = () => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [results, setResults] = useState([]);
  const [files, setFiles] = useState([]);
  const [searchParams] = useSearchParams();

  const ResolveData = useCallback(
    (key) => {
      const found: any = results.find((el: any) => el.Type === key);
      return found ? found.Results : [];
    },
    [results]
  );

  const GetSearchResults = async (query: string) => {
    try {
      setProgress(true);
      const response = await GlobalSearch(query);
      if (response.StatusCode === 200 && response.Data) {
        setResults(response.Data);
        const found = response.Data.find((el: any) => el.Type === "Files");
        if (found) setFiles(found.Results);
      } else {
        console.error("API search error:", response);
      }
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setProgress(false);
    }
  };

  const OpenDetailView = (key: string, item: any) => {
    try {
      let link = "";
      if (key === "Assets") {
        link = `/assets/details/${item.Id}`;
      } else if (key === "Floors") {
        link = `/buildings-location/${item.LocationId}/floors`;
      } else if (key === "Users") {
        link = `/users/manageuser/${item.Id}`;
      } else if (key === "AssetTypes") {
        link = `/settings/asset-types`;
      } else if (key === "Locations") {
        link = `/buildings-location/${item.Id}`;
      } else if (key === "MaintenanceSchedule") {
        link = `/maintenance/maintenance-tasks/${item.Id}`;
      } else if (key === "Inspections") {
        link = `/management/inspections/${item.Id}`;
      } else if (key === "Vendors") {
        link = `/users/vendors/${item.Id}`;
      } else if (key === "TICKET") {
        link = `/maintenance/tickets-list/${item.Id}`;
      } else if (key === "AssetTransaction") {
        link = `/management/check-InOuts`;
      } else if (key === "Groups") {
        link = `/users/team/dashboard/${item.Id}`;
      }
      if (link) {
        navigate(link);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const NoResultsFound = () => {
    try {
      let allEmpty = true;
      ResultTables.forEach((item) => {
        const count = ResolveData(item.key).length;
        if (count !== 0) allEmpty = false;
      });
      return allEmpty;
    } catch (error) {
      console.log("error", error);
    }
    return false;
  };

  useEffect(() => {
    const query = searchParams.get("search");
    if (query) {
      GetSearchResults(query);
    }
  }, [searchParams]);

  return (
    <>
      <PageTitleBox name="" pageTitle="" title="Search Results" />
      {(progress || NoResultsFound()) && (
        <Card>
          <Card.Body className="text-center p-3">
            {progress && <AppLoader />}
            {!progress && NoResultsFound() && <div>No Results Found</div>}
          </Card.Body>
        </Card>
      )}
      {ResultTables.map((item, index) => (
        <div key={index}>
          <SearchResultBlock
            title={item.title}
            columns={item.columns}
            rows={ResolveData(item.key)}
            isFetching={progress}
            OnRowClick={(row) => OpenDetailView(item.key, row)}
          />
        </div>
      ))}
      {files.length !== 0 && (
        <div className="mb-3">
          <h5>Files & Attachments</h5>
          <Card>
            <Card.Body>
              <ImageGrid
                files={files}
                setFiles={setFiles}
                showUploader={false}
                hideRemoveIcon={true}
              />
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

const SearchResultBlock = React.memo(
  ({
    title,
    columns,
    rows,
    isFetching,
    OnRowClick,
  }: {
    title: string;
    columns: any[];
    rows: any[];
    isFetching: boolean;
    OnRowClick: (a: any) => void;
  }) => {
    return (
      <div className={classNames("mb-3", { "d-none": rows.length === 0 })}>
        <h5>{title}</h5>
        <Card>
          <Card.Body>
            <div className="table-responsive custom-table-wrapper no-scrollbar">
              <table className="table table-centered react-table">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index} scope="col">
                        {column.Header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {!isFetching && !rows.length && (
                    <tr className="text-center text-danger fs-18">
                      <td colSpan={columns.length ? columns.length : 8}>
                        No Data Found!
                      </td>
                    </tr>
                  )}
                  {isFetching && !rows.length && (
                    <tr className="text-center text-success fs-18">
                      <td colSpan={columns.length ? columns.length : 8}>
                        <AppLoader />
                      </td>
                    </tr>
                  )}
                  {!isFetching &&
                    rows.map((row, i) => (
                      <tr
                        key={i}
                        className="cursor-pointer"
                        onClick={() => {
                          OnRowClick(row);
                        }}
                      >
                        {columns.map((col, j) => (
                          <td key={j}>
                            {typeof col.accessor === "function"
                              ? col.accessor(row)
                              : row[col.accessor] || "NA"}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
);

export default SearchResults;
