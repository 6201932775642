import { FormatDate, durationOptions } from "common/utility";
import { Badge } from "react-bootstrap";
import { getDateFromISOString, getTimeWithAMPM } from "utils";

export const USER_SEARCH_COLUMNS = [
  {
    Header: "Profile Pic",
    accessor: (row: any) => {
      return (
        <div className="d-flex align-items-center">
          <img
            src={row.FullFileUrl}
            className="avatar-sm m-1 rounded-circle"
            height="48"
            alt=""
          />
        </div>
      );
    },
  },
  {
    Header: "First Name",
    accessor: "FirstName",
  },
  {
    Header: "Last Name",
    accessor: "LastName",
  },
  {
    Header: "Buildings / locations",
    accessor: (row: any) => {
      let value = "NA";
      try {
        value = row.Locations[0].Name;
      } catch (error) {
        // console.log('erorr', error);
      }
      return value;
    },
  },
  {
    Header: "Work Email",
    accessor: "WorkEmail",
  },
  {
    Header: "Team",
    accessor: (row: any) => {
      return row.GroupTitle || "NA";
    },
  },
  {
    Header: "Assigned To",
    accessor: "AssignedSuprvisor",
  },
  {
    Header: "Status",
    accessor: (row: any) => {
      return (
        <Badge
          bg={row.IsActive ? "success" : "danger"}
          className="w-100 text-capitalize text-light rounded-pill fs-12 px-2"
        >
          {row.IsActive ? "Active" : "InActive"}
        </Badge>
      );
    },
  },
  {
    Header: "Asset Access",
    accessor: (row: any) => (row.AuthorityToCheckOutAssets ? "Yes" : "No"),
  },
];

export const ASSET_SEARCH_COLUMNS = [
  {
    Header: "Id",
    accessor: "Id",
  },
  {
    Header: "Image",
    accessor: (row: any) => {
      return (
        <img
          src={row.FullImageFileUrl}
          className="avatar-sm m-1 rounded-circle"
          alt=""
        />
      );
    },
  },
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "Added By",
    accessor: (row: any) => {
      return row.AddedByName || "NA";
    },
  },
  {
    Header: "Room Name",
    accessor: (row: any) => {
      return row.RoomName || "NA";
    },
  },
  {
    Header: "Buildings/Locations",
    accessor: (row: any) => {
      return row.LocationName || "NA";
    },
  },

  {
    Header: "Status",
    accessor: (row: any) => {
      return (
        <Badge
          bg={row.IsActive ? "success" : "danger"}
          className="width-xs text-capitalize text-light rounded-pill fs-12 px-2"
        >
          {row.IsActive ? "Active" : "InActive"}
        </Badge>
      );
    },
  },
  {
    Header: "Added On",
    accessor: (row: any) => {
      return FormatDate(row.AddedOn);
    },
  },
  {
    Header: "Assigned To",
    accessor: (row: any) => {
      return `${row.Assigned || "NA"}`;
    },
  },
  {
    Header: "Cost",
    accessor: (row: any) => {
      return `$${row.Cost || "0"}`;
    },
  },
];

export const ASSET_TYPE_SEARCH_COLUMNS = [
  {
    Header: "Item",
    accessor: "Name",
  },
];

export const FLOOR_SEARCH_COLUMNS = [
  {
    Header: "Id",
    accessor: "Id",
  },
  {
    Header: "Floor",
    accessor: "Name",
  },
];

export const LOCATION_SEARCH_COLUMNS = [
  {
    Header: "ID",
    accessor: "Id",
  },
  {
    Header: "Image",
    accessor: (row: any) => {
      return (
        <div className="d-flex align-items-center">
          <img
            src={row.FullFileUrl}
            className="avatar-sm m-1 rounded-circle"
            height="48"
            alt=""
          />
        </div>
      );
    },
  },
  {
    Header: "Buildings/Locations",
    accessor: "Name",
  },
  {
    Header: "Total Floors",
    accessor: "FloorCount",
  },
  {
    Header: "Total Rooms",
    accessor: "RoomCount",
  },
  {
    Header: "City",
    accessor: "CityName",
  },
  {
    Header: "State",
    accessor: "StateName",
  },
];

export const RESPONSE_REQUEST_SEARCH_COLUMNS = [
  {
    Header: "Id",
    accessor: "a",
  },
  {
    Header: "Message",
    accessor: "a",
  },
  {
    Header: "Requested By",
    accessor: "a",
  },
  {
    Header: "Requested On",
    accessor: "a",
  },
  {
    Header: "Status",
    accessor: "a",
  },
];

export const CHECK_IN_OUT_SEARCH_COLUMNS = [
  {
    Header: "User Checked",
    accessor: "TeamName",
  },
  {
    Header: "Check Out Date",
    accessor: (row: any) => {
      return getDateFromISOString(row.OutDateTime);
    },
  },
  {
    Header: "Out Time",
    accessor: (row: any) => {
      return getTimeWithAMPM(row.OutDateTime);
    },
  },
  {
    Header: "Check In Date",
    accessor: (row: any) => {
      if (row.TransactionType === "IN") {
        return getDateFromISOString(row.InDateTime);
      }
    },
  },

  {
    Header: "In Time",
    accessor: (row: any) => {
      if (row.TransactionType === "IN") {
        return getTimeWithAMPM(row.InDateTime);
      }
      return "-";
    },
  },
  {
    Header: "Duration",
    accessor: (row: any) => {
      let val = "_";
      if (row.Duration) {
        val = durationOptions["" + row.Duration] || "";
      }
      return val;
    },
  },
];

export const INSPECTION_SEARCH_COLUMNS = [
  {
    Header: "ID",
    accessor: "Id",
  },
  {
    Header: "Image",
    accessor: (row: any) => (
      <div>
        <img
          src={row.AssetFileFullUrl}
          className="avatar-sm m-1 rounded-circle"
          alt=""
        />
      </div>
    ),
  },
  {
    Header: "Asset Name",
    accessor: "AssetName",
  },
  {
    Header: "Inspected By",
    accessor: "InspectorName",
  },
  {
    Header: "Inspected On",
    accessor: (row: any) => {
      const inspectedDate = new Date(row?.AddedOn);
      return inspectedDate.toISOString().split("T")[0];
    },
  },
  {
    Header: "Duration",
    accessor: (row: any) => {
      return `${row.Duration} ${row.DurationUnit}`;
    },
  },
  {
    Header: "Result",
    accessor: "Description",
  },
  {
    Header: "Timer Duration",
    accessor: "TimelogDurationDisplayValue",
  },
];

export const TICKET_WO_SEARCH_COLUMNS = [
  {
    Header: "Ticket",
    accessor: "Id",
  },
  {
    Header: "Image",
    accessor: (row: any) => {
      return row.ItemFileFullUrl ? (
        <div className="d-flex align-items-center">
          <img
            src={row.ItemFileFullUrl}
            className="avatar-sm m-1 rounded-circle"
            height="48"
            alt=""
          />
        </div>
      ) : (
        "NA"
      );
    },
  },
  {
    Header: "Item",
    accessor: (row: any) => {
      return (
        <span>
          {row.ItemName || "NA"}
          <br />
          Metering Hours Used:{" "}
          {row.TotalUsageFromReading || row.TotalUsageFromReading === 0
            ? row.TotalUsageFromReading
            : "NA"}
        </span>
      );
    },
  },
  {
    Header: "Title",
    accessor: (row: any) => {
      return <span className="table-text">{row.Title || "NA"}</span>;
    },
  },
  {
    Header: "Description",
    accessor: (row: any) => {
      return <span className="table-text">{row.Description}</span>;
    },
  },
  {
    Header: "Request By",
    accessor: (row: any) => {
      return row.RequestedByName || "NA";
    },
  },
  {
    Header: "Assign To",
    accessor: (row: any) => {
      return row.VendorName || "NA";
    },
  },
  {
    Header: "Status",
    accessor: (row: any) => {
      const statusText = row.Status ? row.Status.toLowerCase() : "";
      const capitalizedStatus = statusText
        .split(" ")
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return (
        <Badge
          bg={
            ["OPEN", "TODO", "IN PROGRESS"].includes(row.Status?.toUpperCase())
              ? "danger"
              : ["CLOSE", "CLOSED", "DONE"].includes(row.Status?.toUpperCase())
              ? "success"
              : "secondary"
          }
          className="w-100 text-capitalize text-light rounded-pill fs-12 px-2"
        >
          {capitalizedStatus}
        </Badge>
      );
    },
  },
  {
    Header: "Type",
    accessor: "Type",
  },
  {
    Header: "Due Date",
    accessor: (row: any) => row.DueDate?.substring(0, 10),
  },
  {
    Header: "Timer Duration",
    accessor: "TimelogDurationDisplayValue",
  },
];

export const MAINTENANCE_SEARCH_COLUMNS = [
  {
    Header: "Code",
    accessor: "Id",
  },
  {
    Header: "Image",
    accessor: (row: any) => {
      return (
        <div className="d-flex align-items-center">
          <img
            src={row.FullItemImageFileURL}
            className="avatar-sm m-1 rounded-circle"
            height="48"
            alt=""
          />
        </div>
      );
    },
  },
  {
    Header: "Assets",
    accessor: (row: any) => {
      return (
        <span>
          {row.ItemName || "NA"}
          <br />
          Metering Hours Used:{" "}
          {row.TotalUsageFromReading || row.TotalUsageFromReading === 0
            ? row.TotalUsageFromReading
            : "NA"}
        </span>
      );
    },
  },
  {
    Header: "Description",
    accessor: "Description",
  },
  {
    Header: "Assigned To",
    accessor: (row: any) => row.AssignedName || "NA",
  },
  {
    Header: "Requested For",
    accessor: (row: any) => row.ItemName || "NA",
  },
  {
    Header: "Main. Type",
    accessor: "MaintType",
  },
  {
    Header: "Status",
    accessor: "Status",
  },
  {
    Header: "Timer Duration",
    accessor: "TimelogDurationDisplayValue",
  },
];

export const VENDOR_SEARCH_COLUMNS = [
  {
    Header: "Company Logo",
    accessor: (row: any) => {
      return (
        <div className="d-flex align-items-center">
          <img
            src={row.FullFileUrl}
            className="avatar-sm m-1 rounded-circle"
            height="48"
            alt=""
          />
        </div>
      );
    },
  },
  {
    Header: "Company Name",
    accessor: "CompanyName",
  },
  {
    Header: "Main Contact Person",
    accessor: "MainContact",
  },
  {
    Header: "Title",
    accessor: "Title",
  },
  {
    Header: "City",
    accessor: "CityName",
  },
  {
    Header: "Email",
    accessor: "VendorDetails.Email",
  },
  {
    Header: "Type of Services",
    accessor: (row: any) => {
      return row.Services || "NA";
    },
  },
  {
    Header: "Website",
    accessor: "Website",
  },
  {
    Header: "Contact Phone",
    accessor: "ContactPhone",
  },
];

export const GROUP_SEARCH_COLUMNS = [
  {
    Header: "Logo",
    accessor: (row: any, index: number) => {
      return (
        <img
          key={index}
          src={row.PrimaryImageFileFullURL}
          className="avatar-sm m-1 rounded-circle"
          alt=""
        />
      );
    },
  },
  {
    Header: "ID",
    accessor: "Id",
  },
  {
    Header: "Team Name",
    accessor: "Title",
  },
  {
    Header: "Team Lead",
    accessor: "GroupLeaderName",
  },
  {
    Header: "Updated By",
    accessor: "CreatedName",
  },
  {
    Header: "Date",
    accessor: (row: any) => {
      return FormatDate(row.AddedOn, "lll");
    },
  },
];
