import { InputCtrl, SelectCtrl } from "controllers";
import { useForm } from "react-hook-form";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { signupVendor } from "helpers/api/vendor";
import { useToast } from "hooks";
import { useNavigate } from "react-router-dom";
import { getCities, getCountries, getStates } from "helpers";
import AsyncSelectCtrlV1 from "controllers/AsyncSelectCtrlV1";
import PhoneCtrl from "controllers/PhoneCtrl";

const VendorRegister = () => {
  const [inProgress, setInProgress] = useState(false);
  const { showToast, dissmisToast } = useToast();
  const navigate = useNavigate();

  const defaultValues: any = {
    CompanyName: "",
    MainContact: "",
    Title: "",
    Address: "",
    CityId: "",
    Website: "",
    ContactPhone: "",
    Email: "",
    TypeOfService: "",
    TimeZone: "US",
  };

  const {
    control,
    handleSubmit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
    watch,
    setValue,
  } = useForm<any>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  const onSubmit = async (data: any) => {
    const key = "RegisterVendor";
    try {
      setInProgress(true);
      showToast("info", "Your request is being processed", key);
      console.log(data, "data");
      const response = await signupVendor(data);
      console.log(response, "response");
      dissmisToast(key);
      if (response.StatusCode === 200) {
        showToast("success", response.Message);
        navigate("/auth/login");
      } else {
        showToast("error", response.Message);
      }
      setInProgress(false);
    } catch (error: any) {
      dissmisToast(key);
      console.error("Error occurred:", error);
      showToast("error", error.message);
      setInProgress(false);
    }
  };

  const watchCountryId = watch("CountryId");
  const watchStateId = watch("StateId");

  return (
    <>
      <div className='text-center'>
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ height: "100vh" }}
        >
          <h1 className='h1 mt-1 mb-3 text-primary'>Vendor Registration</h1>
          <form className='w-50 text-start' onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={12}>
                    <Row>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='text'
                          name='CompanyName'
                          id='CompanyName'
                          placeholder='Company Name'
                          label='Company Name'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-3'
                          componentName='FullName'
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='text'
                          name='MainContact'
                          id='MainContactPerson'
                          placeholder='Main Contact Person'
                          label='Main Contact Person'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-3'
                          componentName='FullName'
                        />
                      </Col>
                      <Col xl={6}>
                        <SelectCtrl
                          control={control}
                          label='Title'
                          name='Title'
                          id='Title'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          placeholder='Role/Title'
                          options={[
                            {
                              label: "Superintendent",
                              value: "Superintendent",
                            },
                          ]}
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='text'
                          name='Address'
                          id='CompanyAddress'
                          placeholder='Address'
                          label='Address'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-3'
                          componentName='Address'
                        />
                      </Col>
                      <Col xl={6}>
                        <AsyncSelectCtrlV1
                          control={control}
                          name="CountryId"
                          id="CountryId"
                          placeholder="Select country"
                          label="Country"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          options={[]}
                          promiseMethod={getCountries}
                          onSelect={()=>{
                            setValue("StateId", "");
                            setValue("CityId", "");
                          }}
                        />
                      </Col>
                      <Col xl={6}>
                        <AsyncSelectCtrlV1
                          control={control}
                          name="StateId"
                          id="StateId"
                          placeholder="Start typing to select state"
                          label="State"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          options={[]}
                          promiseMethod={getStates}
                          params={{countryId: watchCountryId}}
                          canFetch={!!watchCountryId}
                          onSelect={()=>{
                            setValue("CityId", "");
                          }}
                        />
                      </Col>
                      <Col xl={6}>
                        <AsyncSelectCtrlV1
                          control={control}
                          name="CityId"
                          id="CityId"
                          placeholder="Start typing to select city"
                          label="City"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3"
                          options={[]}
                          promiseMethod={getCities}
                          params={{stateId: watchStateId}}
                          canFetch={!!watchStateId}
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='text'
                          name='Website'
                          id='Website'
                          placeholder='Website'
                          label='Website'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-3'
                          componentName='Website'
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='email'
                          name='Email'
                          id='Email'
                          placeholder='Email'
                          label='Email'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-3'
                          componentName='Email'
                        />
                      </Col>
                      <Col xl={6}>
                        <PhoneCtrl
                          control={control}
                          name="ContactPhone"
                          id="ContactPhone"
                          placeholder="Contact Number"
                          label="Contact Number"
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className="mb-3 w-100"
                        />
                      </Col>
                      <Col xl={6}>
                        <InputCtrl
                          control={control}
                          type='text'
                          name='TypeOfService'
                          id='TypeOfService'
                          placeholder='TypeOfService'
                          label='Type Of Service'
                          showError={showError}
                          required={true}
                          disabled={inProgress}
                          className='mb-1'
                          componentName='FullName'
                        />
                      </Col>
                      {/* <Button
                        variant='success'
                        className='rounded-pill me-1 py-0 px-1 '
                      >
                        Electrical
                        <Badge bg='success' pill className='fs-5 ms-1'>
                          x
                        </Badge>
                      </Button>

                      <Button
                        variant='success'
                        className='rounded-pill me-1 py-0 px-1'
                      >
                        HVAC
                        <Badge bg='success' pill className='fs-5 ms-1'>
                          x
                        </Badge>
                      </Button>
                      <Button
                        variant='success'
                        className='rounded-pill me-1 py-0 px-1'
                      >
                        Plumbing
                        <Badge bg='success' pill className='fs-5 ms-1'>
                          x
                        </Badge>
                      </Button> */}
                    </Row>
                  </Col>
                </Row>
                {/* <div className='border border-2 my-1'></div> */}
              </Card.Body>
            </Card>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='button-list'>
                <Button
                  variant='primary'
                  type='submit'
                  disabled={inProgress}
                  className='rounded-pill px-4 py-2'
                >
                  Register Now
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VendorRegister;
