import { IIcon } from "interfaces";

const CheckIcon = ({
  width = 20,
  height = 20,
  color = "currentColor",
}: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 6L5.28571 10L11 2" stroke={color} />
      </svg>
    </div>
  );
};

export default CheckIcon;
