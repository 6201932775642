import { InputCtrl, SelectCtrl } from "controllers";
import { useForm } from "react-hook-form";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useToast } from "hooks";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/linkedLogo.svg";
import PhoneCtrl from "controllers/PhoneCtrl";
import AsyncSelectCtrlV1 from "controllers/AsyncSelectCtrlV1";
import { getCities, getCountries, getStates } from "helpers";
import { IndependentSignUp } from "helpers/api/user";
import { getRoles } from "helpers/api/role";
import { ReduceString } from "common/utility";
import CongratulationModal from "components/CongratulationModal";

interface IUser {
  FirstName: string;
  LastName: string;
  Password: string;
  WorkEmail: string;
  Email: string;
  ContactPhone: string;
  Gender: string;
  RoleId: number;
  TeamId: number;
  GroupId: number;
  IsActive: boolean;
  AssignedSuprvisor: string;
  BusinessIds: string;
  LocationIds: string;
  Address: string;
  AuthorityToCheckOutAssets: boolean;
  DOB: string;
  CityId: number;
}

const defaultValues: IUser = {
  FirstName: "",
  LastName: "",
  Password: "",
  WorkEmail: "",
  Email: "",
  ContactPhone: "",
  Gender: "",
  RoleId: 0,
  TeamId: 0,
  GroupId: 0,
  IsActive: true,
  AssignedSuprvisor: "",
  BusinessIds: "",
  LocationIds: "",
  Address: "",
  AuthorityToCheckOutAssets: true,
  DOB: "",
  CityId: 0,
};

const PasswordValidation = {
  maxLength: {
    value: 12,
    message: "Password cannot exceed more than 12 characters",
  },
  minLength: {
    value: 4,
    message: "Password length should be at least 4 characters",
  },
};

const PersonalAccountSignup = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [progress, setProgress] = useState(false);
  const [show, setShow] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm<any>({
    defaultValues: {
      ...defaultValues,
    },
    mode: "onBlur",
  });

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  const onSubmit = async (data: any) => {
    try {
      setProgress(true);
      let requestBody = {
        FirstName: data.FirstName,
        LastName: data.LastName,
        Password: data.Password,
        WorkEmail: data.WorkEmail,
        Email: data.Email,
        ContactPhone: data.ContactPhone,
        Gender: data.Gender,
        RoleId: 0,
        TeamId: 0,
        GroupId: 0,
        IsActive: true,
        ProfilePicId: 0,
        AssignedSuprvisor: "",
        BusinessIds: "",
        LocationIds: "",
        Address: data.Address,
        AuthorityToCheckOutAssets: true,
        DOB: data.DOB,
        CityId: data.CityId,
      };
      const result = await IndependentSignUp(requestBody);
      if (result && result.StatusCode === 200) {
        setShow(true);
        showToast("success", result.Message);
      } else {
        showToast("error", result.Message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setProgress(false);
    }
  };

  const watchCountryId = watch("CountryId");
  const watchStateId = watch("StateId");
  return (
    <>
      <div className="personal-account">
        <Card className="m-0">
          <div className="d-flex flex-column align-items-center justify-content-center p-2 shadow rounded">
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-lg">
                <img src={Logo} alt="" height="100" />
              </span>
            </Link>
            <h2 className="mb-0">Sign up for your personal account with us</h2>
            <div className="sub-text">
              Fill up the form details below to proceed further with accessing
              your personal account.
            </div>
            {/*  */}
            <form
              name="personal-account-form"
              id="personal-account-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Card className="border-0">
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="FirstName"
                        id="FirstName"
                        placeholder="Enter first name"
                        label="First Name"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        componentName="FullName"
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="LastName"
                        id="LastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        componentName="Name"
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="WorkEmail"
                        id="WorkEmail"
                        placeholder="Enter email"
                        label="Work Email"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        componentName="Email"
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="Email"
                        id="Email"
                        placeholder="Enter email"
                        label="Email"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        componentName="Email"
                      />
                    </Col>
                    <Col md={6}>
                      <SelectCtrl
                        control={control}
                        name="Gender"
                        id="Gender"
                        placeholder="Enter Gender"
                        label="Gender"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        options={[
                          {
                            label: "Male",
                            value: "Male",
                          },
                          {
                            label: "Female",
                            value: "Female",
                          },
                        ]}
                      />
                    </Col>
                    <Col md={6}>
                      <PhoneCtrl
                        control={control}
                        name="ContactPhone"
                        id="ContactPhone"
                        placeholder="Enter Phone"
                        label="Phone"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3 w-100"
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="date"
                        name="DOB"
                        id="DOB"
                        placeholder="Date"
                        label="Date of Birth"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="password"
                        name="Password"
                        id="Password"
                        placeholder="Enter Password"
                        label="Password"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        rules={PasswordValidation}
                      />
                    </Col>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="password"
                        name="ConfirmPassword"
                        id="ConfirmPassword"
                        placeholder="Enter Confirm Password"
                        label="Confirm Password"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        rules={{
                          ...PasswordValidation,
                          validate: (value) => {
                            return (
                              value === getValues("Password") ||
                              "The passwords do not match"
                            );
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="border border-1 my-3"></div>
                  <Row>
                    <Col md={6}>
                      <InputCtrl
                        control={control}
                        type="text"
                        name="Address"
                        id="Address"
                        placeholder="Address"
                        label="Address"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                      />
                    </Col>
                    <Col md={6}>
                      <AsyncSelectCtrlV1
                        control={control}
                        name="CountryId"
                        id="CountryId"
                        placeholder="Select country"
                        label="Country"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        options={[]}
                        promiseMethod={getCountries}
                        onSelect={() => {
                          setValue("StateId", "");
                          setValue("CityId", "");
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AsyncSelectCtrlV1
                        control={control}
                        name="StateId"
                        id="StateId"
                        placeholder="Start typing to select state"
                        label="State"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        options={[]}
                        promiseMethod={getStates}
                        params={{ countryId: watchCountryId }}
                        canFetch={!!watchCountryId}
                        onSelect={() => {
                          setValue("CityId", "");
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <AsyncSelectCtrlV1
                        control={control}
                        name="CityId"
                        id="CityId"
                        placeholder="Start typing to select city"
                        label="City"
                        showError={showError}
                        required={true}
                        disabled={progress}
                        className="mb-3"
                        options={[]}
                        promiseMethod={getCities}
                        params={{ stateId: watchStateId }}
                        canFetch={!!watchStateId}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div className="button-list w-100">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={progress}
                        className="px-4 py-1 w-100"
                      >
                        Submit to proceed further
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </form>
            {/*  */}
          </div>
        </Card>
        <CongratulationModal show={show} setShow={setShow} />
      </div>
    </>
  );
};

export default PersonalAccountSignup;
