export enum AssetActionTypes {
	API_RESPONSE_SUCCESS = "@@asset/API_RESPONSE_SUCCESS",
	API_RESPONSE_ERROR = "@@asset/API_RESPONSE_ERROR",

	GET_ASSET_LIST = "@@asset/GET_ASSET_LIST",
	RESET = "@@team/RESET",
}

export interface AssetList {
	Id: number;
	Name: string;
	Code: string;
	CategoryId: number;
	Type: number;
	LocationId: number;
	Room: string;
	Department: string;
	Model: string;
	Make: string;
	SerialNumber: string;
	Height: number;
	Width: number;
	Depth: number;
	ImagesIds: string;
	IsActive: boolean;
	Cost: number;
    ReplacementCost:number;
    PurchasedOn:string;
    Notes:string;
	AddedBy: number;
	ModifiedBy: number;
	AddedOn: string;
	ModifiedOn: string;
}

export interface AssetPagination {
    CurrentPage: number;
	TotalPages: number;
	TotalItems: number;
	ItemsPerPage: number;
    Items?: AssetList[]
}

export interface AssetStateTypes extends AssetPagination {
    AssetTableColumns: Array<{Header: string, accessor: string}>
	AssetList: AssetList[]
	loading: boolean;
	isSuccessVendorList: boolean;
	isSuccessVendorDetails: boolean;
}

