import {
  MENU_ITEMS,
  HORIZONTAL_MENU_ITEMS,
  TWO_COl_MENU_ITEMS,
} from "../constants/menu";
import { MenuItemTypes } from "../interfaces";
import { replaceSlashesAndSpacesWithHyphens } from "../utils";

const getMenuItems = () => {
  // NOTE - You can fetch from server and return here as well
  return MENU_ITEMS;
};

const getHorizontalMenuItems = () => {
  // NOTE - You can fetch from server and return here as well
  return HORIZONTAL_MENU_ITEMS;
};

const getTwoColumnMenuItems = () => {
  // NOTE - You can fetch from server and return here as well
  return TWO_COl_MENU_ITEMS;
};

const findAllParent = (
  menuItems: MenuItemTypes[],
  menuItem: MenuItemTypes
): string[] => {
  let parents: string[] = [];
  const parent = findMenuItem(menuItems, menuItem["parentKey"]);

  if (parent) {
    parents.push(parent["key"]);
    if (parent["parentKey"])
      parents = [...parents, ...findAllParent(menuItems, parent)];
  }

  return parents;
};

const findMenuItem = (
  menuItems: MenuItemTypes[] | undefined,
  menuItemKey: MenuItemTypes["key"] | undefined
): MenuItemTypes | null => {
  if (menuItems && menuItemKey) {
    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i];
      }
      var found = findMenuItem(menuItems[i].children, menuItemKey);
      if (found) return found;
    }
  }
  return null;
};

const generateMenuItems = (
	items: Array<any>,
	key: string,
    parentKey: string,
	preUrl = "",
	allowedUsers: Array<string>,
  pageNumber?:number
) => {
	const tempList: {
		key: string;
		label: any;
		url: string;
		parentKey: string;
    		allowedUsers: Array<string>;
        extraFields: Record<string, any>;
	}[] = [];
	if (items.length) {
		items.map((item: any) => {
			const name = item[key] || "";
			if (name) {
				const value_hyphen = replaceSlashesAndSpacesWithHyphens(name);
				tempList.push({
					key: `as-${value_hyphen}`,
					label: name,
					url: `${preUrl}${value_hyphen}`,
					parentKey:  parentKey,
					allowedUsers: allowedUsers,
                    extraFields: item
				});
			}
			return item;
		});
	}
    return tempList;
};

export {
  getMenuItems,
  getHorizontalMenuItems,
  getTwoColumnMenuItems,
  findAllParent,
  findMenuItem,
  generateMenuItems
};
