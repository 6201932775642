import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
	getVendorList as getVendorListApi,
	getVendorDetails as getVendorDetailsApi,
} from "../../helpers/";

// actions
import { vendorApiResponseSuccess, vendorApiResponseError } from "./actions";

// constants
import { VendorActionTypes } from "./interfaces";

interface IVendorData {
	payload: {
		pageNumber: number;
		pageSize: number;
		query: string;
		id: string | number;
	};
	type: string;
}

/**
 * GetAll the vendor
 * @param {*} payload - pageNumber, pageSize and query
 */
function* getAll({
	payload: { pageNumber, pageSize, query },
	type,
}: IVendorData): SagaIterator {
	try {
		const response = yield call(getVendorListApi, {
			pageNumber,
			pageSize,
			query,
		});
		const data = response;
        console.log(data)
		if (data.StatusCode === 200) {
			// NOTE - You can change this according to response format from your api
			const apiResData = { ...data.Data };
			yield put(
				vendorApiResponseSuccess(
					VendorActionTypes.GET_VENDOR_LIST,
					apiResData,
				),
			);
		} else {
			yield put(
				vendorApiResponseError(
					VendorActionTypes.GET_VENDOR_LIST,
					data.Message,
				),
			);
		}
	} catch (error: any) {
        console.log(error);
		yield put(
			vendorApiResponseError(VendorActionTypes.GET_VENDOR_LIST, error),
		);
	}
}

/**
 * Get the vendor details
 * @param {*} payload - id
 */
function* get({ payload: { id }, type }: IVendorData): SagaIterator {
	try {
		const response = yield call(getVendorDetailsApi, { id });
		const data = response;
		if (data.StatusCode === 200) {
			// NOTE - You can change this according to response format from your api
			const apiResData = { ...data.Data };
			yield put(
				vendorApiResponseSuccess(
					VendorActionTypes.GET_VENDOR_DETAILS,
					apiResData,
				),
			);
		} else {
			yield put(
				vendorApiResponseError(
					VendorActionTypes.GET_VENDOR_DETAILS,
					data.Message,
				),
			);
		}
	} catch (error: any) {
		yield put(
			vendorApiResponseError(VendorActionTypes.GET_VENDOR_DETAILS, error),
		);
	}
}

export function* watchGetAll() {
	yield takeEvery(VendorActionTypes.GET_VENDOR_LIST, getAll);
}

export function* watchGet() {
	yield takeEvery(VendorActionTypes.GET_VENDOR_DETAILS, get);
}

function* vendorSaga() {
	yield all([fork(watchGetAll), fork(watchGet)]);
}

export default vendorSaga;
