import { APICore } from "./apiCore";

const api = new APICore();

// country
function getCountries({ pageNumber = 1, pageSize = 20, query = "" }: { pageNumber: number; pageSize: number, query: string }) {
  const baseUrl = "/api/Country/GetCountries";
  return api.get(`${baseUrl}`, { pageNumber, pageSize, query });
}

function getStates({ countryId, pageNumber = 1, pageSize = 20, query = "" }: { countryId: number, pageNumber: number; pageSize: number, query: string }){
    const baseUrl = "/api/Country/GetStates";
    return api.get(`${baseUrl}`, { countryId, pageNumber, pageSize, query }); 
}

function getCities({ stateId, pageNumber = 1, pageSize = 20, query = '' }: { stateId: number, pageNumber: number; pageSize: number, query: string }){
    const baseUrl = "/api/Country/GetCities";
    return api.get(`${baseUrl}`, { stateId, pageNumber, pageSize, query }); 
}

function getAddressInfo({ placeId }: { placeId: number }){
    const baseUrl = "/api/Country/GetAddressInfo";
    return api.get(`${baseUrl}`, { placeId }); 
}



export { getCountries, getStates, getCities, getAddressInfo };
