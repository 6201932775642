import { APICore } from "./apiCore";

const api = new APICore();

// assets
function getAssetDetails(params: { id: number | string }) {
  const baseUrl = "/api/Assets/Get";
  return api.get(`${baseUrl}`, params);
}

function getAssetsList(params: {
  pageNumber?: number;
  pageSize?: number;
  query?: string;
  assetTypeId?: number;
  assetIds?: string;
  groupId?: string;
  locationId?: number;
  [x: string]: any;
}) {
  const baseUrl = "/api/Assets/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getAllQRCodesPDF(params: any) {
  const baseUrl = "/api/Assets/GetAllQRCodesPDF";
  return api.get(`${baseUrl}`, params);
}

function saveAssets(params: any, action?: string) {
  let baseUrl = "/api/Assets/Save";
  if(action){
    baseUrl = `${baseUrl}?action=${action}`;
  }
  console.log(baseUrl, action);
  return api.create(`${baseUrl}`, params);
}

function deleteAssets(id: any) {
  const baseUrl = "/api/Assets/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

function changeQuantity(data: any) {
  const baseUrl = "/api/Assets/ChangeQuantity";
  return api.create(`${baseUrl}`, data);
}

function unlinkAsset(params: any) {
  const baseUrl = `/api/Assets/UnlinkAsset?assetId=${params.assetId}&unlinkAssetId=${params.unlinkAssetId}`;
  return api.create(`${baseUrl}`, {});
}

export {
  getAssetsList,
  saveAssets,
  getAssetDetails,
  deleteAssets,
  getAllQRCodesPDF,
  changeQuantity,
  unlinkAsset,
};
