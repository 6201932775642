import { useEffect } from "react";

export const usePaginationHash = (urlPrefix:string, pageNumber : number) => {
useEffect(() => {
  const updateHash = () => {
    const isValidHashFormat = new RegExp(
      `^#${urlPrefix}(?:/[a-zA-Z_]+)?(?:\\?[^#]*)?\\bpage=\\d+$`
    ).test(window.location.hash);

    if (isValidHashFormat) {
      const routeMatch = window.location.hash.match(
        new RegExp(
          `^#(${urlPrefix}(?:/[a-zA-Z_]+)?)\\b(?:\\?[^#]*)?\\bpage=\\d+$`
        )
      );
      const routePart = routeMatch ? routeMatch[1] : `/${urlPrefix}`;

      const updatedPageHash = `#${routePart}?page=${pageNumber}`;
      window.location.hash = updatedPageHash;
    }
  };

  const hasValidPageNumber = pageNumber;

  if (hasValidPageNumber) {
    updateHash();
  }

  return;
}, [urlPrefix, pageNumber]);
  };
  