export const PERMISSION_KEYS = {
  // pin in assets
  ADD_PIN_IN_ASSETS: "Assets.Add",
  EDIT_PIN_IN_ASSETS: "Assets.Update",
  SAVE_PIN_IN_ASSETS: "Assets.Write",
  // inventory control
  ADD_INVENTORY_CONTROL: "Inventory.Add",
  EDIT_INVENTORY_CONTROL: "Inventory.Update",
  SAVE_INVENTORY_CONTROL: "Inventory.Write",
  // manage vendor
  VIEW_MANAGE_VENDOR: "Vendor.View",
  ADD_MANAGE_VENDOR: "Vendor.Add",
  EDIT_MANAGE_VENDOR: "Vendor.Update",
  SAVE_MANAGE_VENDOR: "Vendor.Write",
  DELETE_MANAGE_VENDOR: "Vendor.Delete",
  // rooms in buildings
  VIEW_ROOMS_IN_BUILDINGS: "Room.View",
  ADD_ROOMS_IN_BUILDINGS: "Room.Add",
  EDIT_ROOMS_IN_BUILDINGS: "Room.Update",
  SAVE_ROOMS_IN_BUILDINGS: "Room.Write",
  DELETE_ROOMS_IN_BUILDINGS: "Room.Delete",
};

export const CALENDAR_PERMISSION_KEYS = {
  // organizational
  VIEW_ORGANIZATION_CALENDAR: "OrganizationalCalendar.View",
  EDIT_ORGANIZATION_CALENDAR: "OrganizationalCalendar.Edit",
  // team
  VIEW_TEAM_CALENDAR: "TeamCalendar.View",
  EDIT_TEAM_CALENDAR: "TeamCalendar.Edit",
  // job scheduler
  VIEW_JOB_SCHEDULER: "JobScheduler.View",
  EDIT_JOB_SCHEDULER: "JobScheduler.Edit",
};
