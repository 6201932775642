import { AssetActionTypes, AssetList } from "./interfaces";

export interface AssetActionType {
  type:
    | AssetActionTypes.API_RESPONSE_SUCCESS
    | AssetActionTypes.API_RESPONSE_ERROR
    | AssetActionTypes.GET_ASSET_LIST
    | AssetActionTypes.RESET;
  payload: {} | string;
}

// common success
export const assetApiResponseSuccess = (
  actionType: string,
  data: AssetList | AssetList[] | {}
): AssetActionType => ({
  type: AssetActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const assetApiResponseError = (
  actionType: string,
  error: string
): AssetActionType => ({
  type: AssetActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getAssetList = (
  pageNumber: number,
  pageSize: number,
  query: string
): AssetActionType => ({
  type: AssetActionTypes.GET_ASSET_LIST,
  payload: { pageNumber, pageSize, query },
});

export const resetAsset = (): AssetActionType => ({
  type: AssetActionTypes.RESET,
  payload: {},
});
