import { APICore } from "./apiCore";

const api = new APICore();

// AssetTransaction
function getAssetTransactionDetails(params: { id: number | string }) {
	const baseUrl = "/api/AssetTransaction/Get";
	return api.get(`${baseUrl}`, params);
}

function getAssetTransactions(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
}) {
	const baseUrl = "/api/AssetTransaction/GetAll";
	return api.get(`${baseUrl}`, params);
}

function saveAssetTransaction(params: any) {
	const baseUrl = "/api/AssetTransaction/Save";
	return api.create(`${baseUrl}`, params);
}

function deleteAssetTransaction(id: string | number) {
	const baseUrl = "/api/AssetTransaction/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}
function getAssetTransactionsMostrecentEndpoint(id: number | undefined) {
	const baseUrl = "/api/AssetTransaction/GetMostRecent";
	const params = { assetId: id };
  
	return api.get(baseUrl, params);
  }

export {
	getAssetTransactions,
	saveAssetTransaction,
	getAssetTransactionDetails,
	deleteAssetTransaction,
	getAssetTransactionsMostrecentEndpoint
};
