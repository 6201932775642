import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { IBaseCtrl, ISelectOption } from "../interfaces";
import Select from "react-select/async";

export interface IAsyncSelectCtrlV1 extends IBaseCtrl {
  label: string;
  options: ISelectOption[];
  endAdornment?: any;
  startAdornment?: any;
  startAdornmentIcon?: any;
  endAdornmentIcon?: any;
  inputProps?: any;
  onSelect?: (option: ISelectOption) => void;
  cb?: () => void;
  defaultValue?: any;
  promiseMethod?: any;
  params?: any;
  canFetch?: boolean;
  labelKey?: string;
  secondaryLabelKey?: string;
  valueKey?: string;
  showNoneOption?: boolean;
  formatOptionLabel?: any;
}

const AsyncSelectCtrlV1 = ({
  control,
  showError,
  placeholder,
  name,
  required,
  disabled,
  id,
  label,
  onSelect,
  className = "",
  cb = () => {},
  defaultValue = undefined,
  promiseMethod = () => {},
  params = {},
  canFetch = true,
  labelKey = "Name",
  valueKey = "Id",
  secondaryLabelKey = "",
  showNoneOption = false,
  formatOptionLabel = undefined,
}: IAsyncSelectCtrlV1) => {
  const [selected, setSelected] = useState<any>("");
  const rules: any = {
    required: required,
    pattern: {
      value: /^(?!\s*$).+/,
      message: "Field is invalid",
    },
  };
  const promiseOptions = async (inputValue: string) => {
    try {
      if (!canFetch) {
        return;
      }
      if (id) {
        const response = await promiseMethod({
          pageNumber: 1,
          query: inputValue,
          pageSize: 10,
          ...params,
        });
        if (response.Data && response.Data.Items) {
          const data = response.Data.Items.map((item: any) => {
            const label = labelKey.split('.').reduce((obj, key) => obj && obj[key], item);
            let secondaryLabel = "";
            const value = valueKey.split('.').reduce((obj, key) => obj && obj[key], item);
            const obj: any = {
              label: label || "NA",
              value,
            }
            if(secondaryLabelKey){
              secondaryLabel = secondaryLabelKey.split('.').reduce((obj, key) => obj && obj[key], item);
              if(secondaryLabel){
                obj.secondaryLabel = secondaryLabel;
              }
            }
            return obj
          });
          if(showNoneOption){
            data.unshift({
              label: "None",
              value: 0
            })
          }
          return data;
        }
      }
      return [];
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div>
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Form.Group className={`mb-3 ${className}`}>
              <Form.Label htmlFor={id}>
                {label} {required && <span className='text-danger'>*</span>}
              </Form.Label>
              <Select
                {...field}
                inputId={id}
                isDisabled={disabled}
                placeholder={placeholder}
                loadOptions={promiseOptions}
                cacheOptions
                defaultOptions
                value={selected}
                onChange={(selectedOption: any) => {
                  if (selectedOption) {
                    field.onChange(selectedOption.value);
                    if (onSelect) {
                      onSelect(selectedOption);
                    }
                    setSelected(selectedOption);
                  } else {
                    field.onChange(null);
                    setSelected(null);
                    if (onSelect) {
                      // @ts-ignore
                      onSelect(null);
                    }
                  }
                }}
                formatOptionLabel={
                  formatOptionLabel ? formatOptionLabel : (item) => item.label
                }
                isClearable
                // styles={{
                //   clearIndicator: (base) => ({
                //     ...base,
                //     display: "none",
                //   }),
                // }}
              />
              {showError && showError(name) ? (
                <Form.Control.Feedback type='invalid' className='d-block'>
                  {showError(name)}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </>
        )}
      ></Controller>
    </div>
  );
};

export default AsyncSelectCtrlV1;
