import { TeamList } from './Interfaces';

export const TeamTableColumns: {Header: string, accessor: keyof TeamList | "" } []=[
    {
        Header: "ID",
        accessor: "Id",
      },
      {
        Header: "Team Name",
        accessor: "Name",
      },
      {
        Header: "Contact Person",
        accessor: "MainContactPerson",
      },
      {
        Header: "City",
        accessor: "CityId",
      },
      {
        Header: "Email",
        accessor: "MainContactEmail",
      },
      {
        Header: "Phone",
        accessor: "PhoneNumber",
      },
      {
        Header: "Address",
        accessor: "Address",
      },
]