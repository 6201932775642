export enum TeamActionTypes {
	API_RESPONSE_SUCCESS = "@@team/API_RESPONSE_SUCCESS",
	API_RESPONSE_ERROR = "@@team/API_RESPONSE_ERROR",

	GET_TEAM_LIST = "@@team/GET_TEAM_LIST",
	RESET = "@@team/RESET",
}

export interface TeamList {
	Id: number;
	Name: string;
	Address: string;
	CityId: string;
	LogoFileId: number;
	TimeZone: string;
	TypeId: string;
	Website: string;
	PhoneNumber: string;
	MainContactPerson: string;
	MainContactTitle: string;
	MainContactEmail: string;
	MainContactPhoneNumber: string;
	SecondaryContactPerson: string;
	SecondaryContactTitle: string;
	SecondaryContactEmail: string;
	SecondaryContactPhoneNumber: string;
	AddedBy: number;
	ModifiedBy: number;
	AddedOn: string;
	ModifiedOn: string;
}

export interface TeamPagination {
    CurrentPage: number;
	TotalPages: number;
	TotalItems: number;
	ItemsPerPage: number;
    Items?: TeamList[]
}

export interface TeamStateTypes extends TeamPagination {
    TeamTableColumns: Array<{Header: string, accessor: string}>
	TeamList: TeamList[]
	loading: boolean;
	isSuccessVendorList: boolean;
	isSuccessVendorDetails: boolean;
}

