import { createUrlWithParams } from "common/utility";
import { APICore } from "./apiCore";

const api = new APICore();

// team
function getAssetsTypeList(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
}) {
  const baseUrl = "/api/AssetsType/GetAll";
  return api.get(`${baseUrl}`, params);
}

function saveAssetsType(params: any) {
  const baseUrl = "/api/AssetsType/Save";
  return api.create(`${baseUrl}`, params);
}

function deleteAssetsType(id: string | number) {
  const baseUrl = "/api/AssetsType/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

function assignAssignmentGetAll(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
}) {
  const baseUrl = "/api/AssetsType/AssignAssignmentGetAll";
  return api.get(`${baseUrl}`, params);
}

const assignAssetType = (params: any) => {
  const baseUrl = "/api/AssetsType/AssignAssignment";
  return api.create(createUrlWithParams(baseUrl, params), {});
};

const updateAssignementStatus = (params: any) => {
  const baseUrl = "/api/AssetsType/UpdateStatus";
  return api.create(createUrlWithParams(baseUrl, params), {});
};

function deleteAllAssignments(id: string | number) {
  const baseUrl = "/api/AssetsType/DeleteAllAssignments";
  return api.delete(`${baseUrl}?id=${id}`);
}

export {
  getAssetsTypeList,
  saveAssetsType,
  deleteAssetsType,
  assignAssetType,
  assignAssignmentGetAll,
  updateAssignementStatus,
  deleteAllAssignments,
};
