import { getMenuItems } from "../../helpers/menu";
import { MenuItemTypes } from "../../interfaces";
import { removeDuplicates } from "../../utils";
// import { MenuItemTypes } from "../../interfaces";
import {
	MenuItemsActionTypes,
	MenuItemsStateTypes,
	MenuItemsActionType,
} from "./interfaces";

const INIT_STATE: MenuItemsStateTypes = {
	menuItems: getMenuItems(),
};

const updateMenuItems = (state: MenuItemsStateTypes, data: any) => {
	const menuItems = state.menuItems || getMenuItems() || [];
    const tempObj: {[x: string]: boolean} = {}
	if (data && data.length) {
		data.map((item: any) => {
			const index = menuItems.findIndex(
				(item2: MenuItemTypes) => item2.key === item?.parentKey,
			);
			if (index) {
                if(!tempObj[item?.parentKey]){
                    tempObj[item?.parentKey] = true;
                    menuItems[index]["children"] = [item];
                } else if (menuItems[index].children) {
					menuItems[index].children?.push(item);
				}
			}
			return item;
		});
	}
    const unique1 = removeDuplicates(menuItems, 'key')
    const uniqueMenuItems = unique1.map((item: MenuItemTypes) => {
        if(item.children) {
            item.children = removeDuplicates(item.children, 'key')
        }
        return item;
    })
	return uniqueMenuItems;
};

const MenuItems = (
	state: MenuItemsStateTypes = INIT_STATE,
	action: MenuItemsActionType,
): any => {
	switch (action.type) {
		case MenuItemsActionTypes.UPDATE_MENU_ITMES:
			return {
				...state,
				menuItems: updateMenuItems(state, action.payload?.data),
			};
		case MenuItemsActionTypes.RESET:
			return {
				...state,
				menuItems: [],
			};
		default:
			return { ...state };
	}
};

export default MenuItems;
