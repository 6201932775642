import { AssetActionTypes, AssetList, AssetPagination, AssetStateTypes } from "./interfaces";
import { AssetTableColumns } from "./constants";


const INIT_STATE = {
    AssetTableColumns: AssetTableColumns,
	AssetList: [],
	loading: false,
	isSuccessVendorList: false,
	isSuccessVendorDetails: false,
	CurrentPage: 1,
	TotalPages: 0,
	TotalItems: 0,
	ItemsPerPage: 20,
};

interface AssetActionType {
	type:
		| AssetActionTypes.API_RESPONSE_SUCCESS
		| AssetActionTypes.API_RESPONSE_ERROR
		| AssetActionTypes.GET_ASSET_LIST
		| AssetActionTypes.RESET;
	payload: {
		actionType?: string;
		data?: any;
		error?: string;
	};
}


const Asset = (
	state: AssetStateTypes = INIT_STATE,
	action: AssetActionType,
): any => {
	switch (action.type) {
		case AssetActionTypes.API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case AssetActionTypes.GET_ASSET_LIST: {
					const {
						CurrentPage = 1,
						TotalPages,
						TotalItems,
						ItemsPerPage,
						Items,
					} = action.payload.data;
					return {
						...state,
						AssetList: Items,
						CurrentPage,
						TotalPages,
						TotalItems,
						ItemsPerPage,
						isSuccessVendorList: true,
						loading: false,
					};
				}
				default:
					return { ...state };
			}
		case AssetActionTypes.API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case AssetActionTypes.GET_ASSET_LIST: {
					return {
						...state,
						assetListError: action.payload.error,
						isSuccessVendorList: false,
						loading: false,
					};
				}
				default:
					return { ...state };
			}
		case AssetActionTypes.GET_ASSET_LIST:
			return { ...state, loading: true };
		case AssetActionTypes.RESET:
			return {
				...state,
				loading: false,
				assetList: [],
				isSuccessTeamList: false,
			};
		default:
			return { ...state };
	}
};

export default Asset;