import {TeamActionTypes, TeamList, TeamPagination, TeamStateTypes} from './Interfaces';
import { TeamTableColumns } from './Constants';



const INIT_STATE = {
    TeamTableColumns: TeamTableColumns,
	TeamList: [],
	loading: false,
	isSuccessVendorList: false,
	isSuccessVendorDetails: false,
	CurrentPage: 1,
	TotalPages: 0,
	TotalItems: 0,
	ItemsPerPage: 20,
};


interface TeamActionType {
	type:
		| TeamActionTypes.API_RESPONSE_SUCCESS
		| TeamActionTypes.API_RESPONSE_ERROR
		| TeamActionTypes.GET_TEAM_LIST
		| TeamActionTypes.RESET;
	payload: {
		actionType?: string;
		data?: any;
		error?: string;
	};
}


const Team = (
	state: TeamStateTypes = INIT_STATE,
	action: TeamActionType,
): any => {
	switch (action.type) {
		case TeamActionTypes.API_RESPONSE_SUCCESS:
			switch (action.payload.actionType) {
				case TeamActionTypes.GET_TEAM_LIST: {
					const {
						CurrentPage = 1,
						TotalPages,
						TotalItems,
						ItemsPerPage,
						Items,
					} = action.payload.data;
					return {
						...state,
						TeamList: Items,
						CurrentPage,
						TotalPages,
						TotalItems,
						ItemsPerPage,
						isSuccessVendorList: true,
						loading: false,
					};
				}
				default:
					return { ...state };
			}
		case TeamActionTypes.API_RESPONSE_ERROR:
			switch (action.payload.actionType) {
				case TeamActionTypes.GET_TEAM_LIST: {
					return {
						...state,
						teamListError: action.payload.error,
						isSuccessVendorList: false,
						loading: false,
					};
				}
				default:
					return { ...state };
			}
		case TeamActionTypes.GET_TEAM_LIST:
			return { ...state, loading: true };
		case TeamActionTypes.RESET:
			return {
				...state,
				loading: false,
				teamDetails: {},
				teamList: [],
				isSuccessTeamList: false,
			};
		default:
			return { ...state };
	}
};

export default Team;