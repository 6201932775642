import {TeamActionTypes, TeamList} from './Interfaces';

export interface TeamActionType {
    type:
      | TeamActionTypes.API_RESPONSE_SUCCESS
      | TeamActionTypes.API_RESPONSE_ERROR
      | TeamActionTypes.GET_TEAM_LIST
      | TeamActionTypes.RESET
    payload: {} | string;
  }


// common success
export const teamApiResponseSuccess = (
    actionType: string,
    data: TeamList | TeamList[] | {}
  ): TeamActionType => ({
    type: TeamActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
  });
  // common error
  export const teamApiResponseError = (
    actionType: string,
    error: string
  ): TeamActionType => ({
    type: TeamActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
  });
  
  export const getTeamList = (pageNumber: number, pageSize: number, query: string): TeamActionType => ({
    type: TeamActionTypes.GET_TEAM_LIST,
    payload: {pageNumber, pageSize, query},
  });
  

  export const resetTeam = (): TeamActionType => ({
    type: TeamActionTypes.RESET,
    payload: {},
  });