import React from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { ISelectProps } from "../interfaces";
import Select from "react-select";

const SelectCtrl = ({
  control,
  showError,
  placeholder,
  name,
  required,
  disabled,
  id,
  startAdornment,
  endAdornment,
  startAdornmentIcon,
  endAdornmentIcon,
  label,
  options,
  onSelect,
  className = "mb-3",
  formatOptionLabel = undefined
}: ISelectProps) => {
  const rules: any = {
    required: required,
    pattern: {
      value: /^(?!\s*$).+/,
      message: "Field is invalid",
    },
  };
  const onSelectHandler = (e: any) => {
    const value = e?.target?.value;
    let found;
    if (value && Number(value)) {
      found = options.find((option) => Number(option.value) === Number(value));
    } else if (value) {
      found = options.find((option) => option.value === value);
    }
    if (found && onSelect) {
      onSelect(found);
    }
  };
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          <Form.Group className={`${className}`}>
            <Form.Label
              htmlFor={id}
              className={`${label === "" ? "d-none" : ""}`}
            >
              {label} {required && <span className="text-danger">*</span>}
            </Form.Label>
            {/* <Form.Select
							{...field}
							id={id}
							disabled={disabled}
							placeholder={placeholder}
							isInvalid={
								showError && showError(name) ? true : false
							}
                            onSelect={onSelectHandler}
						>
                            {placeholder ? <option>{placeholder}</option>: null}
							{options.map((option, idx) => (
								<option key={idx} value={option.value}>
									{option.label}
								</option>
							))}
						</Form.Select> */}
            <Select
              {...field}
              inputId={id}
              isDisabled={disabled}
              placeholder={placeholder}
              options={options}
              value={options.find((o) => o.value == field.value) || null}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  field.onChange(selectedOption.value);
                  if (onSelect) {
                    onSelect(selectedOption);
                  }
                } else {
                  field.onChange(null);
                  if (onSelect) {
                    // @ts-ignore
                    onSelect(null);
                  }
                }
              }}
              isClearable
              styles={{
                clearIndicator: (base) => ({
                  ...base,
                  display: "none", 
                }),
              }}
              formatOptionLabel={
                formatOptionLabel ? formatOptionLabel : (item) => item.label
              }
            />
            {showError && showError(name) ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                {showError(name)}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </>
      )}
    ></Controller>
  );
};

export default SelectCtrl;
