import { APICore } from "./apiCore";

const api = new APICore();

function saveRole(params: any) {
  const baseUrl = "/api/Roles/Save";
  return api.create(`${baseUrl}`, params);
}

function getRoles(params: {
  pageNumber: number;
  pageSize: number;
  query: string;
  filters?: string;
}) {
  const baseUrl = "/api/Roles/GetAll";
  return api.get(`${baseUrl}`, params);
}

function getRole(params: { id: number | string }) {
  const baseUrl = "/api/Roles/Get";
  return api.get(`${baseUrl}`, params);
}

function deleteRole(id: any) {
  const baseUrl = "/api/Roles/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

function getRolePermissions(params: {roleId: number | string | undefined}) {
  const baseUrl = `/api/Roles/GetRolePermissions`;
  return api.get(`${baseUrl}`, params);
}

function saveRolePermission(data: any) {
  const baseUrl = "/api/Roles/SaveRolePermission";
  return api.create(`${baseUrl}`, data);
}

function deleteRolePermission(data: any) {
  const baseUrl = "/api/Roles/DeleteRolePermission";
  return api.delete(baseUrl, data);
}

export {
  saveRole,
  getRoles,
  getRole,
  deleteRole,
  getRolePermissions,
  saveRolePermission,
  deleteRolePermission
};
