import { APICore } from "./apiCore";

const api = new APICore();

function getAllMaintenanceSchedule(params: {
	pageNumber?: number;
	pageSize?: number;
	query?: string;
	[x: string]: any;
}) {
	const baseUrl = "/api/MaintenanceSchedule/GetAll";
	return api.get(`${baseUrl}`, params);
}

function getMaintenanceSchedule(params: { id: number | string, [x: string]: any }) {
	const baseUrl = "/api/MaintenanceSchedule/Get";
	return api.get(`${baseUrl}`, params);
}

function saveMaintenanceSchedule(params: any) {
	const baseUrl = "/api/MaintenanceSchedule/Save";
	return api.create(`${baseUrl}`, params);
}

function deleteMaintenanceSchedule(id: string | number) {
	const baseUrl = "/api/MaintenanceSchedule/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

export {  getAllMaintenanceSchedule, getMaintenanceSchedule, saveMaintenanceSchedule, deleteMaintenanceSchedule };