import { MenuItemTypes } from "../../interfaces";
import { MenuItemsActionType, MenuItemsActionTypes } from "./interfaces";

export const getAssetTypesList = (): MenuItemsActionType => ({
    type: MenuItemsActionTypes.GET_ASSET_TYPES_LIST,
    payload: { data: []},
  });

export const updateMenuItems = (
	actionType: string,
	data: MenuItemTypes[],
): MenuItemsActionType => ({
	type: MenuItemsActionTypes.UPDATE_MENU_ITMES,
	payload: { data },
});

export const resetMenuItems = (): MenuItemsActionType => ({
	type: MenuItemsActionTypes.RESET,
	payload: {
        data: []
    },
});
