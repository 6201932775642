import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";

const CustomMarker = (props: MarkerComponentProps) => {
	return (
		<p className="custom-marker text-danger">
			<i
				className="bi bi-geo-alt-fill"
				style={{
					fontSize: 38,
				}}
			/>
		</p>
	);
};

interface IImageMarker extends Marker {
	FloorCoordinateTitle: string;
	FloorCoordinateX: number;
	FloorCoordinateY: number;
}

interface ImageMapMarkerProps {
	imageMarker: IImageMarker[];
	imagePath: string;
	onChangeMarker?: (marker: IImageMarker) => void;
	markerMoveAllowed: boolean;
}

const ImageMapMarker = ({
	imageMarker,
	imagePath,
	onChangeMarker = () => 0,
	markerMoveAllowed = true
}: ImageMapMarkerProps, ref:any) => {
	const [markers, setMarkers] = useState<IImageMarker[]>([]);
	const addLeft = -0.99999999999;
	const addTop = -5.99999999999;
	const handleMarker = (marker: Marker) => {
		if(!markerMoveAllowed){
			return;
		}
		const top = +marker.top + addTop;
		const left = +marker.left + addLeft;
		const data: IImageMarker = {
			FloorCoordinateTitle: "Mark",
			FloorCoordinateX: +marker.top,
			FloorCoordinateY: +marker.left,
			top: top,
			left: left,
		};
        onChangeMarker(data)
		setMarkers([data]);
	};

	useEffect(() => {
		if (imageMarker && !markers.length) {
			const tempMarker = imageMarker
				.filter(
					(marker) =>
						marker.FloorCoordinateX || marker.FloorCoordinateY,
				)
				.map((marker) => {
					const top = +marker.top + addTop;
					const left = +marker.left + addLeft;
					return {
						...marker,
						top: top,
						left: left,
					};
				});
			setMarkers(tempMarker);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageMarker]);

    useImperativeHandle(ref, () => {
		return {
            setMarkers 
        }
	});

	return (
		<ImageMarker
			src={imagePath}
			markers={markers}
			onAddMarker={(marker: Marker) => handleMarker(marker)}
			markerComponent={CustomMarker}
		/>
	);
};

export default forwardRef(ImageMapMarker);
