import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { getAssetsList as getAssetsListApi } from "../../helpers";
import { assetApiResponseSuccess, assetApiResponseError } from "./actions";
import { AssetActionTypes } from "./interfaces";


interface AssetData {
	payload: {
		pageNumber: number;
		pageSize: number;
		query: string;
		id: string | number;
	};
	type: string;
}


/**
 * GetAll the vendor
 * @param {*} payload - pageNumber, pageSize and query
 */
function* getAll({
	payload: { pageNumber, pageSize, query },
	type,
}: AssetData): SagaIterator {
	try {
		const response = yield call(getAssetsListApi, {
			pageNumber,
			pageSize,
			query,
		});
		const data = response.data;
		if (data.StatusCode === 200) {
			// NOTE - You can change this according to response format from your api
			const apiResData = { ...data.Data };
			yield put(
				assetApiResponseSuccess(
					AssetActionTypes.GET_ASSET_LIST,
					apiResData,
				),
			);
		} else {
			yield put(
				assetApiResponseError(
					AssetActionTypes.GET_ASSET_LIST,
					data.Message,
				),
			);
		}
	} catch (error: any) {
		yield put(
			assetApiResponseError(AssetActionTypes.GET_ASSET_LIST, error),
		);
	}
}


export function* watchGetAll() {
	yield takeEvery(AssetActionTypes.GET_ASSET_LIST, getAll);
}

function* assetSaga() {
	yield all([fork(watchGetAll)]);
}

export default assetSaga;