import { AssetList } from "./interfaces";

export const AssetTableColumns: {
  Header: string;
  accessor: keyof AssetList | "";
}[] = [
  {
    Header: "Building Location",
    accessor: "",
  },
  {
    Header: "Name",
    accessor: "Name",
  },
  {
    Header: "Room",
    accessor: "Room",
  },
  {
    Header: "Status",
    accessor: "IsActive",
  },
  {
    Header: "Assigned To",
    accessor: "",
  },
  {
    Header: "Vendor 1",
    accessor: "",
  },
  {
    Header: "Vendor 2",
    accessor: "",
  },
  {
    Header: "Cost",
    accessor: "Cost",
  },
  {
    Header: "Notes",
    accessor: "Notes",
  },
];
