import { MenuItemTypes } from "../../interfaces";

export enum MenuItemsActionTypes {
	UPDATE_MENU_ITMES = "@@menuItems/UPDATE_MENU_ITMES",
    
    GET_ASSET_TYPES_LIST = "@@menuItems/GET_ASSET_TYPES_LIST",
	RESET = "@@menuItems/RESET",
}

export interface MenuItemsStateTypes {
    menuItems: MenuItemTypes[]
}

export interface MenuItemsActionType {
	type: MenuItemsActionTypes.UPDATE_MENU_ITMES | MenuItemsActionTypes.RESET | MenuItemsActionTypes.GET_ASSET_TYPES_LIST;
	payload: any
}
