import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import vendorSaga from "./vendor/saga";
import teamSaga from "./team/saga";
import assetSaga from "./assets/saga";
import menuItemsSaga from "./menuItems/saga";


export default function* rootSaga() {
  yield all([authSaga(), layoutSaga(), vendorSaga(), teamSaga(), assetSaga(), menuItemsSaga()]);
}
