import { useSearchParams } from 'react-router-dom';

export const useUserPageAndRow = () => {
  const [searchParams] = useSearchParams();
  const userPageNumber = searchParams.get('page');
  const userRowLimit = localStorage.getItem('userRowLimit');

  return {
    userPageNumber,
    userRowLimit,
  };
};

