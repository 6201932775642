import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { getTeamList as getTeamListApi } from "../../helpers/api/Team";


import { teamApiResponseSuccess, teamApiResponseError } from "./action";

import { TeamActionTypes } from "./Interfaces";



interface TeamData {
	payload: {
		pageNumber: number;
		pageSize: number;
		query: string;
		id: string | number;
	};
	type: string;
}


/**
 * GetAll the vendor
 * @param {*} payload - pageNumber, pageSize and query
 */
function* getAll({
	payload: { pageNumber, pageSize, query },
	type,
}: TeamData): SagaIterator {
	try {
		const response = yield call(getTeamListApi, {
			pageNumber,
			pageSize,
			query,
		});
		const data = response;
		if (data.StatusCode === 200) {
			// NOTE - You can change this according to response format from your api
			const apiResData = { ...data.Data };
			yield put(
				teamApiResponseSuccess(
					TeamActionTypes.GET_TEAM_LIST,
					apiResData,
				),
			);
		} else {
			yield put(
				teamApiResponseError(
					TeamActionTypes.GET_TEAM_LIST,
					data.Message,
				),
			);
		}
	} catch (error: any) {
		yield put(
			teamApiResponseError(TeamActionTypes.GET_TEAM_LIST, error),
		);
	}
}


export function* watchGetAll() {
	yield takeEvery(TeamActionTypes.GET_TEAM_LIST, getAll);
}

function* teamSaga() {
	yield all([fork(watchGetAll)]);
}

export default teamSaga;