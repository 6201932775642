import { Button, Card } from "react-bootstrap";
import { Table, PageTitleBox } from "components";
import { useApi, useToast } from "hooks";
import { useEffect, useState } from "react";
import { useUserPageAndRow } from "helpers";
import { usePaginationHash } from "helpers";
import { ReduceString } from "common/utility";
import Select from "react-select";
import { assignAssignmentGetAll } from "helpers/api/assetType";
import ApproveClientRequestModal from "./ApproveClientRequestModal";

const columns = [
  {
    Header: "User Name",
    accessor: "ClientName",
  },
  {
    Header: "User Email",
    accessor: "ClientEmail",
  },
  {
    Header: "Address",
    accessor: (row: any) => {
      return row.ClientAddress || "NA";
    },
  },
  {
    Header: "Asset Type",
    accessor: (row: any) => {
      return row.AssetTypeName || "NA";
    },
  },
  {
    Header: "Status",
    accessor: (row: any) => {
      let bgClass = "";
      if (ReduceString(row.Status) === "approved") {
        bgClass = "btn-soft-success";
      }
      if (ReduceString(row.Status) === "reject") {
        bgClass = "btn-soft-danger";
      }
      if (ReduceString(row.Status) === "pending") {
        bgClass = "btn-soft-warning";
      }
      return (
        <Button
          className={`text-capitalize rounded-pill fs-12 px-3 py-0 btn ${bgClass}`}
        >
          {row.Status || "NA"}
        </Button>
      );
    },
  },
];

const UserRequests = () => {
  const [show, setShow] = useState(false);
  const [clientData, setClientData] = useState<any>({});
  const [requestStatus, setRequestStatus] = useState({
    label: "Pending",
    value: "PENDING",
  });
  const { userPageNumber, userRowLimit } = useUserPageAndRow();
  const { showToast } = useToast();
  const {
    isLoading,
    list: usersList,
    reCall: refresh,
    pagination,
  } = useApi(
    "GetAssignments",
    (data: any) => {
      let params: any = {
        pageNumber: userPageNumber || 1,
        pageSize: userRowLimit || 20,
        query: "all",
        filters: requestStatus.value,
        ...data,
      };
      return assignAssignmentGetAll({ ...params });
    },
    {
      enabled: false,
      onSuccess: (response) => {
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );

  usePaginationHash("/user-approvals", pagination.pageNumber);
  useEffect(() => {
    let _params: any = {
      pageNumber: userPageNumber || 1,
      pageSize: userRowLimit || 20,
      query: "all",
    };
    refresh({ ..._params });
  }, [userPageNumber, requestStatus]);

  useEffect(() => {
    if (!show) {
      setClientData({});
    }
  }, [show]);

  return (
    <>
      <PageTitleBox name="" pageTitle="User Requests" title="" />
      <ApproveClientRequestModal
        show={show}
        setShow={setShow}
        data={clientData}
        refreshData={() =>
          refresh({
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            query: pagination.query,
          })
        }
      />
      <Card>
        <Card.Body>
          <div className="d-flex align-items-center justify-content-end py-2">
            <div>
              <Select
                value={requestStatus}
                options={[
                  { label: "Pending", value: "PENDING" },
                  { label: "Approved", value: "APPROVED" },
                  { label: "Rejected", value: "REJECT" },
                ]}
                onChange={(selectedOption: any) => {
                  setRequestStatus(selectedOption);
                }}
              />
            </div>
          </div>
          <Table
            isFetching={isLoading}
            columns={columns}
            data={usersList}
            sizePerPageList={[]}
            isSortable={true}
            pagination={true}
            {...pagination}
            onRowClick={(row) => {
              setClientData(row.original);
              setShow(true);
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default UserRequests;
