import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";

import { IGoogleMapProps } from "interfaces";
import { GoogleMap } from "components";

const GoogleMapCtrl = ({
	control,
	showError,
	placeholder,
	name,
	required,
	disabled,
	id,
	label,
	defaultValue,
    onChangePlaceId,
    onChangeInputValue,
    marker
}: IGoogleMapProps) => {
	const rules: any = {
		required: required,
		pattern: {
			value: /^(?!\s*$).+/,
			message: "Field is invalid",
		},
	};
    const autocompleteRef = useRef<any>(null);
    const mainInputRef = useRef<any>(null);
    const autocompleteId = 'autocomplete';

    const onInputChange = (val: string) => {
        onChangeInputValue(val || "")
    }
	return (
		<Controller
			rules={rules}
			name={name}
			control={control}
			render={({ field }) => (
				<>
					<Form.Group className="mb-3">
						<Form.Label htmlFor={id}>
							{label}{" "}
							{required && <span className="text-danger">*</span>}
						</Form.Label>
						<Form.Control
							{...field}
							id={id + autocompleteId}
							disabled={disabled}
							type="text"
							placeholder={placeholder}
							isInvalid={
								showError && showError(name) ? true : false
							}
							defaultValue={defaultValue}
                            ref={mainInputRef}
                            style={{ display: "none" }}
						/>
                        <Form.Control
							type="text"
							name={name}
							id={autocompleteId}
                            placeholder={placeholder}
							defaultValue={defaultValue}
							ref={autocompleteRef}
							required={required}
                            onChange={(e) => {
                                onInputChange(e.target.value)
                            }}
						/>
						{showError && showError(name) ? (
							<Form.Control.Feedback
								type="invalid"
								className="d-block"
							>
								{showError(name)}
							</Form.Control.Feedback>
						) : null}
					</Form.Group>
                    <GoogleMap marker={marker} autocompleteId={id} autocompleteRef={autocompleteRef} onChangePlaceId={onChangePlaceId} onInputChange={onInputChange} />
				</>
			)}
		></Controller>
	);
};

export default GoogleMapCtrl;
