export enum VendorActionTypes {
	API_RESPONSE_SUCCESS = "@@vendor/API_RESPONSE_SUCCESS",
	API_RESPONSE_ERROR = "@@vendor/API_RESPONSE_ERROR",

	GET_VENDOR_LIST = "@@vendor/GET_VENDOR_LIST",
	GET_VENDOR_DETAILS = "@@vendor/GET_VENDOR_DETAILS",

	RESET = "@@vendor/RESET",
}

export interface IVendorDetails {
	Id: number;
	CompanyName: string;
	TypeOfService: string;
	Address: string;
	CityId: number;
	MainContact: string;
	Title: string;
	ContactEmail: string;
	ContactPhone: string;
	TimeZone: string;
	SecondaryContact: string;
	MainPhone: string;
	SecondaryTitle: string;
	SecondaryEmail: string;
	SecondaryPhone: string;
	SecondaryWebsite: string;
	GooglePlaceId: string;
	LogoFileId: number;
	AddedBy: number;
	ModifiedBy: number;
	AddedOn: string;
	ModifiedOn: string;
	CityName: string;
	StateId: number;
	StateName: string;
	CountryId: number;
	CountryName: string;
	LogoURL: any;
	FullFileUrl: string;
}

export interface IVendorPagination {
    CurrentPage: number;
	TotalPages: number;
	TotalItems: number;
	ItemsPerPage: number;
    Items?: IVendorDetails[]
}

export interface VendorStateTypes extends IVendorPagination {
    venderTableColumns: Array<{Header: string, accessor: string}>
	vendorDetails: IVendorDetails | {};
	vendorList: IVendorDetails[]
	loading: boolean;
	isSuccessVendorList: boolean;
	isSuccessVendorDetails: boolean;
}


