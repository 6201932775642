import { PageTitleBox, Table } from "components";
import { useApi } from "hooks";
import { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import IntegrationModal from "./IntegrationModal";
import { getAllIntegrationTypes } from "helpers/api/integrationType";
import RetryIcon from "components/Icons/integrations/RetryIcon";
import CheckIcon from "components/Icons/integrations/CheckIcon";

const ManageIntegrations = () => {
  const columns: {
    Header: string;
    accessor: any;
  }[] = [
    {
      Header: "Items",
      accessor: (row: any) => {
        return row.Name || "NA";
      },
    },
    {
      Header: "Status",
      accessor: (row: any) => {
        return (
          <Badge
            bg={row.IsActive ? "success" : "danger"}
            className="text-capitalize text-light rounded-pill fs-12 px-2"
          >
            {row.IsActive ? "Active" : "InActive"}
          </Badge>
        );
      },
    },
    {
      Header: "Integrate",
      accessor: (row: any) => {
        return (
          <Badge
            className={`text-capitalize rounded-pill fs-12 px-2 ${
              row.IsIntegrated ? "green-badge" : "blue-badge"
            }`}
          >
            {row.IsIntegrated ? (
              <span className="d-flex align-items-center justify-content-center">
                <CheckIcon width={14} height={14} />
                <span className="ms-1">Integrated</span>
              </span>
            ) : (
              <span className="d-flex align-items-center justify-content-center">
                <RetryIcon width={14} height={14} />
                <span className="ms-1">Integrate</span>
              </span>
            )}
          </Badge>
        );
      },
    },
    {
      Header: "Action",
      accessor: () => {
        return <i className="uil uil-edit cursor-pointer text-primary" />;
      },
    },
  ];
  const [show, setShow] = useState(false);
  const [integrationTypeInfo, setIntegrationTypeInfo] =
    useState<any>(undefined);
  const {
    list = [],
    pagination,
    isFetching,
    reCall: fetchIntegrations,
  } = useApi(
    "Integrations",
    (data: any = {}) =>
      getAllIntegrationTypes({
        ...data,
        query: data.query || "all",
      }),
    {
      enabled: false,
      onSuccess: (apiRes) => apiRes.Data,
    }
  );
  useEffect(() => {
    if (!show) {
      fetchIntegrations({
        pageNumber: 1,
        pageSize: 100,
      });
      setIntegrationTypeInfo(undefined);
    }
  }, [show]);
  return (
    <>
      <PageTitleBox
        name=""
        pageTitle="Settings / Company Information / Manage Integrations"
        title="Manage Integrations"
      />
      <Card>
        <Card.Body className="integrations">
          <Table
            columns={columns}
            data={list || []}
            sizePerPageList={[]}
            isFetching={isFetching}
            isSortable={true}
            pagination={false}
            isSearchable={false}
            {...pagination}
            onRowClick={(row, cell) => {
              if (cell?.column?.Header === "Action") {
                setIntegrationTypeInfo({
                  Id: row?.original.Id,
                  Name: row?.original.Name,
                  IsActive: row?.original.IsActive,
                });
                setShow(true);
              }
            }}
          />
        </Card.Body>
      </Card>
      <IntegrationModal
        show={show}
        setShow={setShow}
        integrationTypeInfo={integrationTypeInfo}
      />
    </>
  );
};

export default ManageIntegrations;
