import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const CongratulationModal: React.FC<{ show: boolean, setShow: (a: boolean) => void }> = ({ show, setShow }) => {
    const navigate = useNavigate()
    const LogoutIcon = () => {
        return (
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
            >
                <circle
                    className="path circle"
                    fill="none"
                    stroke="#4bd396"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                />
                <polyline
                    className="path check"
                    fill="none"
                    stroke="#4bd396"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                />
            </svg>
        );
    };

    const onClose = () => {
        setShow(false)
    }
    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header>
            </Modal.Header>
            <div className="logout-checkmark">
                <LogoutIcon />
            </div>
            <Modal.Body>
                <h3 className="congotext text-center">Congratulations!</h3>
                <div className="fw-bold text-center">Your account has been successfully created.</div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button
                    variant="primary"
                    className="px-4 save-btn"
                    onClick={() => navigate("/")}
                >
                    Sign in to continue
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CongratulationModal
