import React, { useRef } from "react";

interface CustomFileUploaderProps {
	isUploading: boolean;
	uploadFile: (file: File) => void;
}

const CustomFileUploader = ({
	isUploading,
	uploadFile,
}: CustomFileUploaderProps) => {
	const fileInputRef: any = useRef(null);
	return (
		<div>
			<input
				ref={fileInputRef}
				type="file"
				multiple={false}
				style={{ display: "none" }}
				disabled={isUploading}
				onChange={(e) => {
					const files = e.target.files;
					if (files?.length) {
						uploadFile(files[0]);
					}
					if (fileInputRef && fileInputRef.current) {
						fileInputRef.current.value = null;
					}
				}}
			/>
		</div>
	);
};

export default CustomFileUploader;
