import { getAllSnippets } from "helpers/api/snippet";
import { useApi, useToast } from "hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TermsConditionsPolicyText = ({
  termsAccepted,
  setTermsAccepted,
}: {
  termsAccepted: boolean;
  setTermsAccepted: (e: boolean) => void;
}) => {
  const { showToast } = useToast();
  let [linksInfo, setLinksInfo] = useState([
    {
      link: "/",
      key: "terms",
    },
    {
      link: "/",
      key: "privacy",
    },
  ]);
  const { reCall: fetchSnippets } = useApi(
    "GetSnippets",
    (data: any) => getAllSnippets(data),
    {
      enabled: false,
      onSuccess: (response) => {
        if (
          response.StatusCode === 200 &&
          response.Data &&
          response.Data.Items
        ) {
          const data = response.Data.Items;
          data.map((item: any) => {
            if (item.Key && typeof item.Key === "string") {
              const idx = linksInfo.findIndex(
                (info) => info.key === item.Key.toLowerCase()
              );
              if (idx !== -1) {
                linksInfo[idx] = {
                  ...linksInfo[idx],
                  link: `/${item.Key}`,
                };
                setLinksInfo([...linksInfo]);
              }
            }
          });
        }
        return response;
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );
  useEffect(() => {
    fetchSnippets({
      pageNumber: 1,
      pageSize: 20,
      query: "all",
    });
  }, []);
  const ResolveLink = (key: string) => {
    try {
      const obj = linksInfo.find((info) => info.key === key);
      if (obj) {
        return obj.link;
      }
    } catch (error) {
      console.log("error", error);
    }
    return "/";
  };
  return (
    <div>
      <div className='form-check'>
        <input
          type='checkbox'
          name='termsAndConditions'
          id='termsAndConditions'
          className='form-check-input'
          onChange={(e) => setTermsAccepted(e.target.checked)}
          checked={termsAccepted}
        />
        <label htmlFor='termsAndConditions' className='text-start'>
          By clicking Log In, you agree to our{" "}
          <Link to={ResolveLink("terms")} target='_blank'>
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link to={ResolveLink("privacy")} target='_blank'>
            Privacy Statement
          </Link>
        </label>
      </div>
    </div>
  );
};

export default TermsConditionsPolicyText;
