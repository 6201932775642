// constants
import { VendorActionTypes } from "./interfaces";

export interface VendorActionType {
  type:
    | VendorActionTypes.API_RESPONSE_SUCCESS
    | VendorActionTypes.API_RESPONSE_ERROR
    | VendorActionTypes.GET_VENDOR_LIST
    | VendorActionTypes.GET_VENDOR_DETAILS
    | VendorActionTypes.RESET
  payload: {} | string;
}


interface IVendor {
    Id: number
    CompanyName: string
    TypeOfService: string
    Address: string
    CityId: number
    MainContact: string
    Title: string
    ContactEmail: string
    ContactPhone: string
    TimeZone: string
    SecondaryContact: string
    MainPhone: string
    SecondaryTitle: string
    SecondaryEmail: string
    SecondaryPhone: string
    SecondaryWebsite: string
    GooglePlaceId: string
    LogoFileId: number
    AddedBy: number
    ModifiedBy: number
    AddedOn: string
    ModifiedOn: string
    CityName: string
    StateId: number
    StateName: string
    CountryId: number
    CountryName: string
    LogoURL: any
    FullFileUrl: string
}

// common success
export const vendorApiResponseSuccess = (
  actionType: string,
  data: IVendor | IVendor[] | {}
): VendorActionType => ({
  type: VendorActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const vendorApiResponseError = (
  actionType: string,
  error: string
): VendorActionType => ({
  type: VendorActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getVendorList = (pageNumber: number, pageSize: number, query: string): VendorActionType => ({
  type: VendorActionTypes.GET_VENDOR_LIST,
  payload: {pageNumber, pageSize, query},
});

export const getVendorDetails = (): VendorActionType => ({
  type: VendorActionTypes.GET_VENDOR_DETAILS,
  payload: {},
});


export const resetVendor = (): VendorActionType => ({
  type: VendorActionTypes.RESET,
  payload: {},
});
