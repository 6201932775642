import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputCtrl } from "controllers";
import { Button, Col, Row } from "react-bootstrap";
import { useToast } from "hooks";
import {
  getAllIntegrations,
  saveIntegrationMultiple,
} from "helpers/api/integration";
import { FLEET_COMPLETE, VERIZON_CONNECT } from "./IntegrationConstants";
import { ReduceString } from "common/utility";
import { saveIntegrationType } from "helpers/api/integrationType";

interface IntergrationProps {
  show: boolean;
  setShow: (a: boolean) => void;
  integrationTypeInfo: any;
}

const IntegrationModal = ({
  show,
  setShow,
  integrationTypeInfo = undefined,
}: IntergrationProps) => {
  const { showToast } = useToast();
  const [fields, setFields] = useState<{ key: string; name: string }[]>([]);
  const [integrationData, setIntegrationData] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [progress, setProgress] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<any>({
    defaultValues: {},
    mode: "onBlur",
  });

  const showError = (_fieldName: string): any => {
    const keyList: Array<string> = _fieldName.split(".");
    const [key1, key2] = keyList;
    let error;
    if (key1 && key2) {
      const errorObj = (errors as any)[key1];
      error = errorObj ? errorObj[key2] : null;
    } else if (key1) {
      error = (errors as any)[key1];
    }
    return error ? error.message || "Field is required" : null;
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    try {
      setProgress(true);
      const arr: any[] = [];
      fields.forEach((item) => {
        const found = integrationData.find(
          (el) => ReduceString(el.Key) === ReduceString(item.key)
        );
        const obj: any = {
          Name: item.name,
          Key: item.key,
          Value: data[item.key].toString(),
          IntegrationTypeId: integrationTypeInfo.Id,
        };
        if (found) {
          obj.Id = found.Id;
        }
        arr.push(obj);
      });
      const result = await saveIntegrationMultiple(arr);
      if (result.StatusCode === 200) {
        onClose();
      } else {
        showToast("error", result.Message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setProgress(false);
    }
  };

  const GetData = async (integrationId: any) => {
    try {
      const result = await getAllIntegrations({
        pageNumber: 1,
        pageSize: 20,
        integrationId: -1,
        typeId: integrationId,
      });
      if (result.StatusCode === 200) {
        const items = result.Data.Items;
        setIntegrationData(items);
        items.forEach((item: any) => {
          const found = fields.find(
            (el) => ReduceString(el.key) === ReduceString(item.Key)
          );
          if (found) {
            setValue(item.Key, item.Value);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const UpdateActiveStatus = async (checked: boolean) => {
    try {
      setProgress(true);
      if (!integrationTypeInfo) return;
      setIsActive(checked);
      await saveIntegrationType({
        ...integrationTypeInfo,
        IsActive: checked,
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setProgress(false);
    }
  };

  const onClose = () => {
    reset({});
    setShow(false);
    setIsActive(false);
    setFields([]);
  };

  useEffect(() => {
    if (show) {
      try {
        if (integrationTypeInfo && integrationTypeInfo.Id) {
          let cmpStr = ReduceString(integrationTypeInfo.Name);
          if (cmpStr === "fleetcomplete" || cmpStr === "fleet_complete") {
            setFields(FLEET_COMPLETE);
          }
          if (cmpStr === "verizonconnect" || cmpStr === "verizon_connect") {
            setFields(VERIZON_CONNECT);
          }
          setIsActive(integrationTypeInfo.IsActive);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [show]);

  useEffect(() => {
    if (fields && fields.length) {
      GetData(integrationTypeInfo.Id);
    }
  }, [fields]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <h4 className="modal-title">
          {integrationTypeInfo && integrationTypeInfo.Name
            ? integrationTypeInfo.Name
            : "Integration Details"}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <form
          name="integration-form"
          id="integration-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={12}>
              {fields.map((item) => (
                <InputCtrl
                  control={control}
                  type="text"
                  name={item.key}
                  id={item.key}
                  placeholder={item.name}
                  label={item.name}
                  showError={showError}
                  required={true}
                  disabled={progress}
                  className="mb-3"
                />
              ))}
              <div className="mb-3">
                <label>
                  Enabled
                  <Form.Check className="form-check form-switch mb-1">
                    <Form.Check.Input
                      type="checkbox"
                      name="enabled"
                      id="enabled-check"
                      onChange={(e) => {
                        UpdateActiveStatus(e.target.checked);
                      }}
                      checked={isActive}
                      disabled={progress}
                    />
                  </Form.Check>
                </label>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="button-list">
              <Button
                variant="primary"
                type="submit"
                disabled={progress}
                className="px-2"
              >
                Submit
              </Button>
              <Button
                variant="outline-primary"
                className="px-2"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default IntegrationModal;
