const replaceSlashesAndSpacesWithHyphens = (str: string) =>
	str.replace(/[\/\s]/g, "-");

const getDateFromISOString = (isoString: string) => {
	const date = new Date(isoString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because it's 0-based
	const day = String(date.getDate()).padStart(2, "0");

	return `${year}-${month}-${day}`;
};

const getTimeWithAMPM = (isoString: string) => {
	const date = new Date(isoString);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? "PM" : "AM";

	const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const getDateTime = (isoString: string) => {
	return `${getDateFromISOString(isoString)} ${getTimeWithAMPM(isoString)}`;
};

const getTime24HourFormat = (isoString: string) => {
	const date = new Date(isoString);
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const formattedHours = hours < 10 ? `0${hours}` : hours;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

	return `${formattedHours}:${formattedMinutes}`;
};

const joinDateAndTime = (date: string, time: string) => {
	const [year = 2023, month = 1, day = 1] = date.split("-").map(Number);
	const [hours = 1, minutes = 0, seconds = 0] = time.split(":").map(Number);

	return new Date(
		year,
		month - 1,
		day,
		hours,
		minutes,
		seconds,
	).toISOString();
};

type AsyncDebouncedFunction<T extends (...args: any[]) => Promise<any>> = (
	...args: Parameters<T>
) => void;

const asyncDebounce = <T extends (...args: any[]) => Promise<any>>(
	func: T,
	wait: number,
): AsyncDebouncedFunction<T> => {
	let timeout: NodeJS.Timeout | null = null;

	return (...args: Parameters<T>) => {
		if (timeout !== null) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			func(...args);
			timeout = null;
		}, wait);
	};
};

type DebouncedFunction<T extends (...args: any[]) => any> = (
	...args: Parameters<T>
) => void;

const debounce = <T extends (...args: any[]) => any>(
	func: T,
	wait: number,
): DebouncedFunction<T> => {
	let timeout: NodeJS.Timeout | null;

	return (...args: Parameters<T>) => {
		if (timeout !== null) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(() => {
			func(...args);
			timeout = null;
		}, wait);
	};
};

const getExtensionFromURL = (url: any) => {
	return url.split(/[#?]/)[0].split(".").pop().trim();
};

const arrayToObject = (
	array: any[],
	key: string,
	valueKey: string,
): { [key: string]: any } => {
	return array.reduce((obj, item, index) => {
		obj[item[key]] = item[valueKey];
		return obj;
	}, {});
};

const isValidDate = (date: any) => {
	const d = new Date(date);
	return d instanceof Date;
};

export {
	replaceSlashesAndSpacesWithHyphens,
	getTimeWithAMPM,
	getTime24HourFormat,
	getDateFromISOString,
	joinDateAndTime,
	getDateTime,
	asyncDebounce,
	debounce,
	getExtensionFromURL,
	arrayToObject,
	isValidDate,
};
