import { SagaIterator } from "@redux-saga/types";
import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { USER_ROLE } from "../../constants";
import { getAssetsTypeList } from "../../helpers";
import { generateMenuItems } from "../../helpers";
import { updateMenuItems } from "./actions";
import { MenuItemsActionTypes } from "./interfaces";
import { IsClientFilter } from "helpers/api/apiCore";
import { ClientFilterId } from "common/utility";

/**
 * GetAll the asset types
 * @param {*} payload - pageNumber, pageSize and query
 */
function* getAll({
  payload: { pageNumber = 1, pageSize = 1000, query = "all" },
  type,
}: any): SagaIterator {
  try {
    let requestParams = {
      pageNumber,
      pageSize,
      query,
      clientId: 0,
    };
    if (IsClientFilter() === "true" && !!ClientFilterId()) {
      requestParams.clientId = ClientFilterId();
    }
    const response = yield call(getAssetsTypeList, { ...requestParams });
    const data = response;
    if (data.StatusCode === 200) {
      // NOTE - You can change this according to response format from your api
      const apiResData = { ...data.Data };
      const Items = apiResData.Items;
      const tempList: Array<any> = generateMenuItems(
        Items,
        "Name",
        "assets",
        "/assets/",
        [
          USER_ROLE.ADMIN,
          USER_ROLE.INDEPENDENT_VENDOR,
          USER_ROLE.INDEPENDENT_USER,
          USER_ROLE.USER,
          USER_ROLE.VENDOR,
        ],
        pageNumber
      );
      tempList.unshift({
        key: "mt-assets",
        label: "All Assets",
        url: `/assets`,
        parentKey: "assets",
        allowedUsers: [
          USER_ROLE.ADMIN,
          USER_ROLE.INDEPENDENT_VENDOR,
          USER_ROLE.INDEPENDENT_USER,
          USER_ROLE.USER,
          USER_ROLE.VENDOR,
        ],
      });
      yield put(
        updateMenuItems(MenuItemsActionTypes.UPDATE_MENU_ITMES, tempList)
      );
    }
  } catch (error: any) {
    console.error(error);
  }
}

export function* watchGetAll() {
  yield takeEvery(MenuItemsActionTypes.GET_ASSET_TYPES_LIST, getAll);
}

function* menuItemsSaga() {
  yield all([fork(watchGetAll)]);
}

export default menuItemsSaga;
