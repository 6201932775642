import { APICore } from "./apiCore";

const api = new APICore();

function saveIntegration(data: any) {
  const baseUrl = "/api/Integration/Save";
  return api.create(`${baseUrl}`, data);
}

function saveIntegrationMultiple(data: any) {
  const baseUrl = "/api/Integration/SaveMultiple";
  return api.create(`${baseUrl}`, data);
}

function getAllIntegrations(params: {
  pageNumber: number;
  pageSize: number;
  query?: string;
  [x: string]: any;
}) {
  const baseUrl = "/api/Integration/GetAll";
  return api.get(`${baseUrl}`, params);
}

function deleteIntegration(id: string | number) {
  const baseUrl = "/api/Integration/Delete";
  return api.delete(`${baseUrl}?id=${id}`);
}

export {
  saveIntegration,
  saveIntegrationMultiple,
  getAllIntegrations,
  deleteIntegration,
};
