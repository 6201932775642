import { APICore } from "./apiCore";

const api = new APICore();

function GetDashboard() {
  const baseUrl = "/api/Utility/GetDashboard";
  return api.get(`${baseUrl}`, {});
}

function GlobalSearch(query: string) {
  const baseUrl = "/api/Utility/GetSearch";
  return api.get(`${baseUrl}`, {query: query});
}

export { GetDashboard, GlobalSearch };
