import { APICore } from "./apiCore";

const api = new APICore();

function getAllInspection(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
}) {
	const baseUrl = "/api/Inspection/GetAll";
	return api.get(`${baseUrl}`, params);
}

function getInspection(params: any) {
	const baseUrl = "/api/Inspection/Get";
	return api.get(`${baseUrl}`, params);
}

function saveInspection(params: any) {
	const baseUrl = "/api/Inspection/Save";
	return api.create(`${baseUrl}`, params);
}

function deleteInspection(id: string | number) {
	const baseUrl = "/api/Inspection/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

export {  getAllInspection, getInspection, saveInspection, deleteInspection };