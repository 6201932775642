import { APICore } from "./apiCore";

const api = new APICore();

// buildingLocation
function getLocationDetails(params: { id: number | string }) {
	const baseUrl = "/api/Location/Get";
	return api.get(`${baseUrl}`, params);
}

function getLocations(params: {
	pageNumber?: number;
	pageSize?: number;
	query?: string;
    teamId?: number;
	[x: string]: any;
}) {
	const baseUrl = "/api/Location/GetAll";
	return api.get(`${baseUrl}`, params);
}
function getRoomImages(params: {
    locationId: number;
	query: string;
}) {
    const baseUrl = "/api/Location/GetRoomImages";
    const url = `${baseUrl}`;
    return api.get(url, {...params}); 
}
function getFloorImages(params: {
    locationId: number;
	query: string;
}) {
    const baseUrl = "/api/Location/GetFloorImages";
    const url = `${baseUrl}`;
    return api.get(url, {...params}); 
}

function saveLocation(params: any) {
	const baseUrl = "/api/Location/Save";
	return api.create(`${baseUrl}`, params);
}

function deleteLocation(id: string | number) {
	const baseUrl = "/api/Location/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}


function getFloorDetails(params: { id: number | string }) {
	const baseUrl = "/api/Floor/Get";
	return api.get(`${baseUrl}`, params);
}

function getFloors(params: {
	pageNumber: number;
	pageSize: number;
	query: string;
    teamId: number;
    LocationId: number;
}) {
	const baseUrl = "/api/Floor/GetAll";
	return api.get(`${baseUrl}`, params);
}

function saveFloor(params: any) {
	const baseUrl = "/api/Floor/Save";
	return api.create(`${baseUrl}`, params);
}

function uploadVirtualTourZip(locationId: any, data: any) {
	const baseUrl = `/api/Location/UploadVirtualTourZip?locationId=${locationId}`;
	return api.create(`${baseUrl}`, data);
}

function deleteFloor(id: string | number) {
	const baseUrl = "/api/Floor/Delete";
	return api.delete(`${baseUrl}?id=${id}`);
}

function getLocationImages(params: {
    locationId: number;
    query: string;
}) {
    const baseUrl = `/api/Location/GetLocationImages`;
    return api.get(baseUrl, params); 
}

export {
	getLocations,
	saveLocation,
	getLocationDetails,
	deleteLocation,
    getFloors,
	saveFloor,
	uploadVirtualTourZip,
	getFloorDetails,
	deleteFloor,
	getRoomImages,
	getFloorImages,
	getLocationImages
};
