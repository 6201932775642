import { getSnippetDetails } from "helpers/api/snippet";
import { useApi, useToast } from "hooks";
import Error404 from "pages/error/Error404";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface ISnippet {
  Id: number;
  Key: string;
  Title: string;
  Content: string;
  Language: string;
}

const SnippetContentPage = () => {
  const [preload, setPreload] = useState(true);
  const params = useParams();
  const [content, setContent] = useState("");
  const { showToast } = useToast();
  const { isFetching, reCall: fetchSnippetDetails } = useApi(
    "GetSnippetDetails",
    (key: any) =>
      getSnippetDetails({
        key: key,
      }),
    {
      enabled: false,
      onSuccess: (response) => {
        const data = response.Data as ISnippet;
        if (response.Data.Content) {
          setContent(response.Data.Content);
        }
        return {
          ...response,
          Data: data,
        };
      },
      onError: (error: Error) => {
        console.error(error);
        showToast("error", error.message);
      },
    }
  );
  useEffect(() => {
    if (params.key) {
      fetchSnippetDetails(params.key);
    }
    setPreload(false);
  }, []);

  return (
    <>
      {isFetching || preload ? (
        <div className='spinner-border spinner-border-sm' role='status' />
      ) : content ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      ) : (
        <Error404 />
      )}
    </>
  );
};

export default SnippetContentPage;
