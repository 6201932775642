import React from "react";
import { Row, Col } from "react-bootstrap";
import { useUser } from "hooks";
import classNames from "classnames";
import { IsIndependentLogin } from "common/utility";

interface PageTitleBoxProps {
	name?: string;
	pageTitle: string | JSX.Element;
	rightItem?: any;
	title?: string;
}

/**
 * PageTitleBox
 */
const PageTitleBox = ({ name, pageTitle, rightItem, title }: PageTitleBoxProps) => {
    const { user } = useUser()
	return (
		<Row>
			<Col>
				<div className="page-title-box align-items-end">
					<div>
						<h3 className={classNames("mb-2 text-color-dark fw-600 fs-100", {"d-none": !title && IsIndependentLogin()})}>{title || user?.TeamName}</h3>
						<h4 className="page-title text-muted">{pageTitle}</h4>
					</div>
					<div className="page-title-right">{rightItem}</div>
				</div>
			</Col>
		</Row>
	);
};

export default PageTitleBox;
