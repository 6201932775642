// Import necessary classes from the Azure SDK
import { BlockBlobClient } from "@azure/storage-blob";
import React, { useState } from "react";

const BlobUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const [sasToken, setSasToken] = useState<string>(""); // State for SAS token

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleSasTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSasToken(event.target.value); // Update SAS token state
  };

  const uploadFile = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    if (!sasToken) {
      alert("Please enter a SAS token!");
      return;
    }

    // Construct the blob name and the full URL with SAS token
    // const blobName = `360/teamId/2024/10/${file.name}`;
    const sasUrl = `https://linked360storage.blob.core.windows.net/attachments/${file.name}?${sasToken}`;
    const blockBlobClient = new BlockBlobClient(sasUrl);

    try {
      // Upload the file
      const response = await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: { blobContentType: file.type },
      });
      console.log("File uploaded successfully:", response);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file.");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <input
        type="text"
        placeholder="Enter SAS Token"
        value={sasToken}
        onChange={handleSasTokenChange}
        style={{ margin: "10px 0", display: "block" }}
      />
      <button onClick={uploadFile}>Upload File</button>
    </div>
  );
};

export default BlobUpload;
