export const FLEET_COMPLETE = [
  { key: "USERNAME", name: "User Name" },
  { key: "PASSWORD", name: "Password" },
  { key: "USERID", name: "User Id" },
  { key: "ORGANIZATION_CONTEXT", name: "Organization Context" },
];

export const VERIZON_CONNECT = [
  { key: "CLIENTID", name: "Client ID" },
  { key: "TOKEN", name: "Token" },
];
