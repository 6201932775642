import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useToast, useUploadFile } from "hooks";
import { IFile } from "interfaces";

export interface IFileUpload {
	multiple: boolean;
	onFileUploaded: (result: IFile) => void;
    onAllFilesUploaded?: (result: IFile[]) => void;
	accept: string;
	id: string;
}

const FileUpload = (
	{ multiple = false, onFileUploaded, accept = "image/*", id, onAllFilesUploaded }: IFileUpload,
	ref: any,
) => {
	const fileInputRef: any = useRef(null);
	const { showToast, dissmisToast } = useToast();

	const { uploading: isUploading, multiUploadFile } = useUploadFile({
		onFire: () => {
			if (!multiple) {
				showToast("info", "Uploading 1%", "UploadImage");
			}
		},
		onPercentage: (
			val: number,
			totalUploaded: number,
			totalFilesCount: number,
		) => {
			if (!multiple) {
				showToast("info", "Uploading 1%", "UploadImage");
			}
			showToast(
				"info",
				`Uploading ${
					multiple ? `${totalUploaded}/${totalFilesCount} ` : ""
				}${val}%`,
				"UploadImage",
			);
		},
		onSuccess: (fileData: any) => {
			onFileUploaded(fileData);
		},
		onCompleted: (results:Array<any>) => {
			dissmisToast("UploadImage");
            if(onAllFilesUploaded){
                onAllFilesUploaded(results);
            }
		},
		onError: (error: any) => {
			dissmisToast("UploadImage");
			showToast("error", error.message ? error.message : error);
		},
	});
	useImperativeHandle(ref, () => {
		return fileInputRef?.current;
	});
	return (
		<div>
			<input
				ref={fileInputRef}
				type="file"
				multiple={multiple}
				style={{ display: "none" }}
				disabled={isUploading}
				id={id}
				accept={accept}
				onChange={(e) => {
					console.log("event", e);
					const files = e.target.files;
					if (files?.length) {
						multiUploadFile(files);
					}
					if (fileInputRef && fileInputRef.current) {
						fileInputRef.current.value = null;
					}
				}}
			/>
			<div>
				<i
					onClick={() => fileInputRef?.current?.click()}
					className="bi bi-upload h3 cursor-pointer"
				></i>
			</div>
		</div>
	);
};

export default forwardRef(FileUpload);
