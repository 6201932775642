import { useEffect, useState } from "react";

interface IApiResponse {
	Data: any;
	Message: any;
	Error: any;
	StatusCode: number;
}

interface IPagination {
	pageCount: number;
	pageNumber: number;
	pageSize: number;
    totalItems: number;
    query: string;
    status: string;
    assetTypeId: string;
    countryId: string;
    stateId: string;
}

interface IPaginationWithFn extends IPagination {
	handleLimitChange: (pageSize: number) => void;
	handlePageChange: (pageNumber: number) => void;
	handleSearch: (query: string) => void;
	handleSelectFilter: (value: string, paramName: 'status' | 'assetTypeId') => void;
	updatePagination: (data: Partial<IPagination>) => void;
}

interface useApiOptionsProps {
	enabled?: boolean;
	onSuccess?: (res: IApiResponse, optional?: any) => any;
	onError?: (error: Error) => any;
	onFire?: () => void;
}

interface useApiResults {
	data: any;
	list: Array<any>;
	details: { [key: string]: any };
	fn: (data?: any) => Promise<IApiResponse>;
	reCall: (data?: any, optional?: any) => void;
	errorMessage: string;
	error: any;
	isLoading: boolean;
	isFetching: boolean;
	isSuccess: boolean;
	isError: boolean;
	pagination: IPaginationWithFn;
}

const initialPageData: IPagination = {
	pageCount: 1,
	pageNumber: 1,
	pageSize: 10,
	totalItems: 0,
	query: 'all',
	status: '',
	assetTypeId: '',
	countryId: '',
	stateId: '',
}

export const useApi = (
	key: string | string[],
	fn: (data?: any, optional?: any) => Promise<IApiResponse>,
	
	options?: useApiOptionsProps,
): useApiResults => {
	const {
		onSuccess = (res: IApiResponse, optional?: any) => res,
		onError = (error: any) => error,
		onFire = () => null,
		enabled = true,
	} = options || {};

	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setFetching] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const [isError, seIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [error, setError] = useState<any>();
	const [data, setData] = useState<any>();
	const [details, setDetails] = useState<{ [x: string]: any }>({});
	const [list, setList] = useState<Array<any>>([]);
	const [tablePagination, setTablePagination] = useState<IPagination>({...initialPageData});

    const handleTPData = (data: Partial<IPagination>) =>{
		const params: any = { ...tablePagination };
		const keys = Object.keys(initialPageData)
		keys.forEach((key: string) => {
			if(typeof params[key] !== 'number' && !params[key]){
				delete params[key]
			}
		})
        return {
            // ...tablePagination,
            ...params,
            ...data
        }
    }

	const handlePageChange = (pageNumber: number) => {
        const curData = handleTPData({pageNumber: pageNumber+1});
        callFn(curData)
		updatePagination(curData);
	};

	const handleLimitChange = (pageSize: number) => {		
        const curData = handleTPData({
			pageSize: pageSize,
			pageNumber: 1,
			pageCount: 1
		});

        callFn(curData)
		updatePagination(curData);
	};

    const handleSearch = (query: string) => { 
        const curData = handleTPData({query: query});
        callFn(curData)
		updatePagination(curData);
    }

	const handleSelectFilter = (value: string, paramName: 'status' | 'assetTypeId' = 'status') => { 
        const curData = handleTPData({[paramName]: value});
        callFn(curData)
		updatePagination(curData);
    }

	const updatePagination = (data: Partial<IPagination>) => {
		setTablePagination((prevTablePagination) => ({
			...prevTablePagination,
			...data,
		}));
	};

	const callFn = async (data?: any, optional?: any) => {
		try {
			onFire();
			setIsLoading(true);
			setFetching(true);
			const apiRes: IApiResponse = await fn(data, optional);
			if (apiRes.StatusCode === 200) {
				const resData = apiRes.Data;
				if (resData.Items) {
					setList(resData.Items);
					updatePagination({
						pageNumber: resData.CurrentPage,
						pageCount: resData.TotalPages,
						pageSize: resData.ItemsPerPage,
                        totalItems: resData.TotalItems
					});
				}
				const tempData = onSuccess(apiRes, optional);
				if (tempData.Data) {
					setDetails(tempData.Data);
				} else {
					setDetails(tempData);
				}
				setData(tempData);
				setSuccess(true);
			} else {
				throw new Error(apiRes.Message || apiRes.Error);
			}
			return apiRes;
		} catch (error: any) {
			console.error(error);
			seIsError(true);
			setSuccess(false);
			onError(error);
			setError(error);
			setErrorMessage(error.message ? error.message : error);
		} finally {
			setIsLoading(false);
			setFetching(false);
		}
	};

	const reCall = async (data?: any, optional?: any) => {
		return await callFn(data, optional);
	};

	useEffect(() => {
		if (enabled) {
			callFn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enabled]);

	return {
		data,
		list,
		details,
		fn,
		reCall,
		errorMessage,
		error,
		isLoading,
		isFetching,
		isSuccess,
		isError,
		pagination: {
			...tablePagination,
			handleLimitChange: handleLimitChange,
			handlePageChange: handlePageChange,
            handleSearch: handleSearch,
            handleSelectFilter: handleSelectFilter,
			updatePagination: updatePagination,
		},
	};
};
