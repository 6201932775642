import { me } from "helpers";
import { AUTH_SESSION_KEY, ReadLocalStorage } from "./utility";

export const ROCKET_CHAT_CREDENTIALS_KEY = "rcCredentials";

export const RCImageBaseUrl = () => {
  return "https://chat.360linked.com";
};

export const GetRCBaseURL = () => {
  return "https://chat.360linked.com/";
};
export const GetRCUserId = () => {
  try {
    let data: any = ReadLocalStorage(ROCKET_CHAT_CREDENTIALS_KEY);
    if (data) {
      return data.userid;
    }
  } catch (error) {
    console.log("error", error);
  }
  return "NA";
};
export const GetRCUserName = () => {
  try {
    let data: any = ReadLocalStorage(ROCKET_CHAT_CREDENTIALS_KEY);
    if (data) {
      return data.username;
    }
  } catch (error) {
    console.log("error", error);
  }
  return "NA";
};
export const GetRCPassword = () => {
  try {
    let data: any = ReadLocalStorage(ROCKET_CHAT_CREDENTIALS_KEY);
    if (data) {
      return data.password;
    }
  } catch (error) {
    console.log("error", error);
  }
  return "NA";
};
export const GetLocalStorageByKey = (key: string) => {
  return localStorage.getItem(key);
};

export const GetChatToken = async () => {
  try {
    const userdata: any = ReadLocalStorage(AUTH_SESSION_KEY);
    if (userdata && userdata.ChatToken) {
      return userdata.ChatToken;
    }
    const result = await me();
    if (result.StatusCode === 200 && result.Data && result.Data.ChatToken) {
      return result.Data.ChatToken;
    }
    return "";
  } catch (error) {
    console.log("error", error);
  }
  return "";
};

export const GetChatHeaders = async () => {
  try {
    // let chatDetails: any = GetLocalStorageByKey("chatDetails");
    // if (chatDetails && IsJSON(chatDetails)) {
    //   chatDetails = JSON.parse(chatDetails);
    //   let obj = {
    //     "X-Auth-Token": chatDetails.authToken,
    //     "content-type": "application/json",
    //     "X-User-Id": GetRCUserId(),
    //   };
    //   return obj;
    // }
    const chatToken = await GetChatToken();
    let obj = {
      "X-Auth-Token": chatToken,
      "content-type": "application/json",
      "X-User-Id": GetRCUserId(),
    };
    return obj;
  } catch (error) {
    return {};
  }
};
