import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { USER_ROLE } from "../constants";
import { UserRole } from "../interfaces";
// components
import PrivateRoute from "./PrivateRoute";
import ManageIntegrations from "pages/admin/company/integrations/ManageIntegrations";
import SearchResults from "pages/admin/searchResult/SearchResults";
import UserRequests from "pages/independentVendor/UserRequests";
const TeamInvites = React.lazy(() => import("pages/clientUser/TeamInvites"));
const ManageVendors = React.lazy(() => import("pages/clientUser/ManageVendors"));
const ManageClients = React.lazy(() => import("pages/independentVendor/ManageClients"));
const ClientForm = React.lazy(() => import("pages/independentVendor/ClientForm"));
const ReportTypes = React.lazy(() => import("pages/admin/Fleet/ReportTypes"));
const ReportForm = React.lazy(() => import("pages/admin/Fleet/ReportForm"));
const Report = React.lazy(() => import("pages/admin/Fleet/Report"));
const ProjectKanban = React.lazy(() => import("pages/admin/projectManagement/ProjectKanban"));
const Projects = React.lazy(() => import("pages/admin/projectManagement/Projects"));
const DetailView = React.lazy(() => import("../pages/admin/maintenance/scheduledmaintenance/DetailView"));
const StaticDataDetails = React.lazy(() => import("../pages/admin/staticData/StaticDataDetails"));
const StaticDataList = React.lazy(() => import("../pages/admin/staticData/StaticDataList"));
const TypeOfOrganization = React.lazy(() => import("../pages/admin/settings/typeofOrganization/TypeOfOrganizations"));
const AllAssetsView = React.lazy(() => import("../pages/admin/assets/AllAssetsView"));
const Countries = React.lazy(() => import("../pages/admin/places/Countries"));
const TrackActivity = React.lazy(() => import("../pages/admin/trackActivity/TrackActivity"));
const States = React.lazy(() => import("../pages/admin/places/States"));
const Cities = React.lazy(() => import("../pages/admin/places/Cities"));
const FloorDetailView = React.lazy(() => import("../pages/admin/BuildingsLocation/FloorDetailView"));
const GroupsList = React.lazy(() => import("../pages/admin/users/Groups/GroupsList"));
const GroupForm = React.lazy(() => import("../pages/admin/users/Groups/GroupForm"));
const RolePermissions = React.lazy(() => import("../pages/admin/settings/role/RolePermissions"));
const GroupsDashboard = React.lazy(() => import("../pages/admin/users/Groups/GroupsDashboard"));
const TimezoneDetails = React.lazy(() => import("../pages/admin/settings/timezone/TimezoneDetails"));
const InspectionForms = React.lazy(() => import("../pages/admin/settings/inspectionForms/InspectionForms"));
const InspectionDetailsV1 = React.lazy(() => import("../pages/admin/management/inspection/InspectionDetailsV1"));
const MaintenanceTasks = React.lazy(() => import("../pages/admin/maintenance/scheduledmaintenance/MaintenanceTasks"));
const AddEditScheduledMaintenanceTasks = React.lazy(() => import("../pages/admin/maintenance/scheduledmaintenance/AddEditScheduleMaintenanceTasks"));
const VendorServices = React.lazy(() => import("../pages/admin/settings/vendorServices/VendorServices"));
const RoomDetailView = React.lazy(() => import("../pages/admin/BuildingsLocation/RoomDetailView"));
const ResponseRequest = React.lazy(() => import("../pages/admin/ResponseRequest"));
const ResponseRequestDetails = React.lazy(() => import("../pages/admin/ResponseRequest/ResponseRequestDetails"));
const ClientApprovals = React.lazy(() => import("../pages/admin/clientApprovals"));
const RecommendedVendorsList = React.lazy(() => import("../pages/admin/users/vendors/RecommendedVendorsList"));
const Areas = React.lazy(() => import("../pages/admin/BuildingsLocation/Areas"));
const AreaDetailView = React.lazy(() => import("../pages/admin/BuildingsLocation/AreaDetailView"));
const Telematics = React.lazy(() => import("../pages/admin/Telematics/Telematics"));
const JobScheduleCalender = React.lazy(() => import("../pages/admin/GlobalCalendar/JobScheduleCalender"));
const Organizational = React.lazy(() => import("../pages/admin/calendars/Organizational"));
const Team = React.lazy(() => import("../pages/admin/calendars/Team"));
const Personal = React.lazy(() => import("../pages/admin/calendars/Personal"));
const Unified = React.lazy(() => import("../pages/admin/calendars/Unified"));
const FleetComplete = React.lazy(() => import("../pages/admin/FleetComplete/FleetComplete"));
// import Root from "./Root";


// lazy load all the views`

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));
const ChangePassword = React.lazy(() => import("../pages/auth/ChangePassword"));
const Impersonate = React.lazy(() => import("../pages/auth/Impersonate"));

const AdminDashboard = React.lazy(() => import("../pages/admin/dashboard/"));

const CompanyInformation = React.lazy(
	() => import("../pages/admin/company/CompanyInformation"),
);



const BuildingsLocationList = React.lazy(() => import("../pages/admin/BuildingsLocation/BuildingsLocationList"));
const BuildingsLocationDetails = React.lazy(() => import("../pages/admin/BuildingsLocation/BuildingsLocationDetails"));
const Floors = React.lazy(() => import("../pages/admin/BuildingsLocation/Floors"));
const FloorDetails = React.lazy(() => import("../pages/admin/BuildingsLocation/FloorDetails"));

const Inspections = React.lazy(() => import("../pages/admin/management/inspection/Inspections"))
const InspectionDetails = React.lazy(() => import("../pages/admin/management/inspection/InspectionDetails"))
const ManageInspection = React.lazy(() => import("../pages/admin/settings/manageInspectiontype/ManageInspection"))

const AssetsInformation = React.lazy(() => import("../pages/admin/assets"));

const TicketsWOsListView = React.lazy(
	() => import("../pages/admin/maintenance/tickets"),
);

const AddEditTicket = React.lazy(
    () => import("../pages/admin/maintenance/tickets/AddEditTicket")
)


const VendorProfileSetup = React.lazy(
    () => import("../pages/admin/settings/vendorProfileSetup/VendorProfileSetup")
)

const VendorsListView = React.lazy(
	() => import("../pages/admin/users/vendors/VendorsList"),
);

const VendorDetailsView = React.lazy(
	() => import("../pages/admin/users/vendors/VendorDetails"),
);

const TeamDetailsView = React.lazy(
	() => import("../pages/admin/users/Teams/TeamDetails"),
);
const TeamListView = React.lazy(
	() => import("../pages/admin/users/Teams/TeamList"),
);

const ManageUsersView = React.lazy(
	() => import("../pages/admin/manageUsers/ManageUsers"),
);
const ManageUsersDetailsView = React.lazy(
	() => import("../pages/admin/manageUsers/ManageUserDetails"),
);

const AddEditAsset = React.lazy(
	() => import("../pages/admin/assets/AddEditAsset"),
);

const CheckInOutView = React.lazy(
	() => import("../pages/admin/management/checkInOut/CheckInOut"),
);
const ScheduleMaintenanceView = React.lazy(
	() =>
		import(
			"../pages/admin/maintenance/scheduledmaintenance/ScheduledMaintenance"
		),
);
const MenuItemsView = React.lazy(
	() => import("../pages/admin/settings/menuitems/MenuItems"),
);

const AssetTypes = React.lazy(
	() => import("../pages/admin/settings/assetTypes/AssetTypes"),
);

const Categories = React.lazy(
	() => import("../pages/admin/settings/categories/Categories"),
);


const Departments = React.lazy(
	() => import("../pages/admin/settings/department/Departments"),
);

const Roles = React.lazy(
	() => import("../pages/admin/settings/role/Roles"),
);

const ReasonPurposes = React.lazy(
	() => import("../pages/admin/settings/reasonPurpose/ReasonPurposes"),
);

const MyProfile = React.lazy(
	() => import("../pages/admin/settings/myProfile/MyProfile"),
);

const Notification = React.lazy(
	() => import("../pages/admin/settings/notifications/Notifications"),
);

const NotificationPreferenceView = React.lazy(
	() => import("../pages/admin/notification/NotificationPreference"),
);

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));

export interface RoutesProps {
	path: RouteProps["path"];
	name?: string;
	element?: RouteProps["element"];
	route?: any;
	exact?: boolean;
	icon?: string;
	header?: string;
	roles?: UserRole[];
	children?: RoutesProps[];
}

// auth
const authRoutes: RoutesProps[] = [
	{
		path: "/auth/login",
		name: "Login",
		element: <Login />,
		route: Route,
	},
	{
		path: "/auth/register",
		name: "Register",
		element: <Register />,
		route: Route,
	},
	{
		path: "/auth/confirm",
		name: "Confirm",
		element: <Confirm />,
		route: Route,
	},
	{
		path: "/auth/forget-password",
		name: "Forget Password",
		element: <ForgetPassword />,
		route: Route,
	},
	{
		path: "/auth/lock-screen",
		name: "Lock Screen",
		element: <LockScreen />,
		route: Route,
	},
	{
		path: "/auth/logout",
		name: "Logout",
		element: <Logout />,
		route: Route,
	},
    {
		path: "/auth/impersonate/:token",
		name: "Impersonate",
		element: <Impersonate />,
		route: Route,
	}
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
	{
		path: "/error-404",
		name: "Error - 404",
		element: <Error404 />,
		route: Route,
	},
	{
		path: "/error-500",
		name: "Error - 500",
		element: <Error500 />,
		route: Route,
	},
];

// dashboards
const dashboardRoutes: RoutesProps = {
	path: "/dashboard/admin",
	name: "Dashboard",
	icon: "airplay",
	// header: "Navigation",
	// element: <AdminDashboard />,
	element: <AdminDashboard />,
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.VENDOR, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER],
};

const calendarRoutes: RoutesProps = {
  path: "/calendar",
  name: "Calendar",
  icon: "uil-calender",
  route: PrivateRoute,
  roles: [USER_ROLE.ADMIN, USER_ROLE.VENDOR, USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
  children: [
		{
			path: "/unified-calendar",
			name: "Unified",
			element: <Unified />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
			route: PrivateRoute,
		},
		{
			path: "/calendar/",
			name: "Organizational",
			element: <Organizational />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
			route: PrivateRoute,
		},
		{
			path: "/team-calendar",
			name: "Team",
			element: <Team />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
			route: PrivateRoute,
		},
		{
			path: "/personal-calendar",
			name: "Personal",
			element: <Personal />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR, USER_ROLE.INDEPENDENT_USER],
			route: PrivateRoute,
		},
		{
			path: "/job-scheduler",
			name: "Job Scheduler",
			element: <JobScheduleCalender />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
			route: PrivateRoute,
		},
  ]
};

const telematicsRoutes: RoutesProps = {
	path: "/verizon",
	name: "Telematics",
	icon: "uil-verizon",
	element: <Telematics />,
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN],
};

const fleetCompleteRoutes: RoutesProps = {
	path: "/fleet-complete",
	name: "Fleet Complete",
	icon: "uil-fleet-complete",
	element: <FleetComplete />,
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN],
};

const fleetReports: RoutesProps = {
	path: "/fleet-reports",
	name: "Report",
	icon: "uil-fleet-complete",
	element: <ReportTypes />,
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN],
	children: [
		{
		  path: "/fleet-reports/form",
		  name: "Report Form",
		  element: <ReportForm />,
		  roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
		  route: PrivateRoute,
		},
		{
			path: "/fleet-reports/report",
			name: "Report Form",
			element: <Report />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		  },
	],
}

const buildingsLocationRoutes: RoutesProps = {
	path: "/buildings-location",
	name: "Buildings Location",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER],
	icon: "uil-briefcase",
	children: [
		{
			path: "/buildings-location/",
			name: "Buildings Location",
			element: <BuildingsLocationList />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
        {
			path: "/buildings-location/:id",
			name: "Buildings Location",
			element: <BuildingsLocationDetails />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
        {
			path: "/buildings-location/:id/floors",
			name: "Floors",
			element: <Floors />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
		{
			path: "/buildings-location/:id/areas",
			name: "Areas",
			element: <Areas />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
		{
			path: "/buildings-location/:id/areas/:areaId",
			name: "Floors",
			element: <AreaDetailView />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
		
        {
			path: "/buildings-location/:id/floors/:floorId",
			name: "Floors",
			element: <FloorDetailView />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
		{
			path: "/buildings-location/:id/floors/:floorId/:roomId",
			name: "Rooms",
			element: <RoomDetailView />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
		{
			path: "/buildings-location/:id/floors/:floorId",
			name: "Floors",
			element: <FloorDetails />,
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR],
			route: PrivateRoute,
		},
	],
};

const ResponseRequestRoute: RoutesProps = {
  path: "/response-requests",
  name: "Response Request",
  route: PrivateRoute,
  roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
  icon: "uil-briefcase",
  children: [
    {
      path: "/response-requests/",
      name: "Response Request",
      element: <ResponseRequest />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
      route: PrivateRoute,
    },
    {
      path: "/response-requests/:id",
      name: "Response Request Details",
      element: <ResponseRequestDetails />,
      roles: [USER_ROLE.ADMIN, USER_ROLE.USER, USER_ROLE.VENDOR],
      route: PrivateRoute,
    },
  ],
};

const UserRequestsRoute: RoutesProps = {
	path: "/vendor-clients",
	name: "User Request",
	route: PrivateRoute,
	roles: [USER_ROLE.VENDOR],
	icon: "uil-briefcase",
	children: [
	  {
		path: "/vendor-clients/",
		name: "Users",
		element: <ManageClients />,
		roles: [USER_ROLE.VENDOR],
		route: PrivateRoute,
	  },
	  {
		path: "/user-approvals",
		name: "User Requests",
		roles: [USER_ROLE.VENDOR, USER_ROLE.INDEPENDENT_VENDOR],
		element: <UserRequests />,
		route: PrivateRoute,
		children: [],
	},
	],
  };

// 
const assetsRoutes: RoutesProps = {
	path: "/assets",
	name: "Assets",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
	element: <AllAssetsView />,
	icon: "uil-briefcase",
	children: [
		{
			path: "/assets/:assetTypes",
			name: "Assets All",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			element: <AssetsInformation />,
			route: PrivateRoute,
		},
		{
			path: "/assets/:assetName/:id",
			name: "Asset Details",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			element: <AddEditAsset />,
			route: PrivateRoute,
		},
	],
};

const searchResultRoutes: RoutesProps = {
	path: "/search-results",
	name: "Search Results",
	route: PrivateRoute,
	roles: [
		USER_ROLE.ADMIN,
		USER_ROLE.SUPERADMIN,
		USER_ROLE.VENDOR,
		USER_ROLE.USER,
		USER_ROLE.INDEPENDENT_VENDOR, 
		USER_ROLE.INDEPENDENT_USER,
	],
	icon: "uil-briefcase",
	element: <SearchResults />,
};

const settingsRoutes: RoutesProps = {
	path: "/settings",
	name: "Settings",
	route: PrivateRoute,
	roles: [
		USER_ROLE.ADMIN,
		USER_ROLE.SUPERADMIN,
		USER_ROLE.VENDOR,
		USER_ROLE.USER,
	],
	icon: "uil-briefcase",
	children: [
		{
			path: "/settings/menu-items",
			name: "Manage Menu Items",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <MenuItemsView />,
			route: PrivateRoute,
		},
		{
			path: "/settings/asset-types",
			name: "Manage Asset Types",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <AssetTypes />,
			route: PrivateRoute,
		},
		{
			path: "/settings/inspection-forms",
			name: "Inspection Forms",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <InspectionForms />,
			route: PrivateRoute,
		},
		{
			path: "/settings/categories",
			name: "Manage Categories",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <Categories />,
			route: PrivateRoute,
		},
		{
			path: "/settings/departments",
			name: "Manage Departments",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <Departments />,
			route: PrivateRoute,
		},
		{
			path: "/settings/roles",
			name: "Manage Roles",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <Roles />,
			route: PrivateRoute,
		},
		{
			path: "/settings/roles/:id",
			name: "Permissions",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <RolePermissions />,
			route: PrivateRoute,
		},
		{
			path: "/settings/reason-purpose",
			name: "Manage Reason Purposes",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <ReasonPurposes />,
			route: PrivateRoute,
		},
        {
			path: "/settings/company-information",
			name: "Company Information",
			element: <CompanyInformation />,
			roles: [USER_ROLE.ADMIN],
			route: PrivateRoute,
		},
		{
			path: "/settings/manage-integrations",
			name: "Manage Integrations",
			element: <ManageIntegrations />,
			roles: [USER_ROLE.ADMIN],
			route: PrivateRoute,
		},
		{
			path:"/vendor-profile-setup",
			name:"Vendor Profile",
			element:<VendorProfileSetup />,
			roles:[USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.VENDOR],
			route: PrivateRoute,

		},
		{
			path: "/settings/manage-inspection",
			name: "Manage Inspection Type",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR],
			element: <ManageInspection/>,
			route: PrivateRoute,
		},
		{
			path: "/settings/type-of-organization",
			name: "Type of Organization",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <TypeOfOrganization />,
			route: PrivateRoute,
		},
		{
			path: "/settings/timezone-details",
			name: "Timezone Details",
			roles: [USER_ROLE.SUPERADMIN],
			element: <TimezoneDetails/>,
			route: PrivateRoute,
		},
		{
			path: "/settings/vendor-services",
			name: "Vendor Services",
			roles: [USER_ROLE.SUPERADMIN],
			element: <VendorServices />,
			route: PrivateRoute,
		},
		{
			path: "/my-profile",
			name: "My Profile",
			roles: [
				USER_ROLE.ADMIN,
				USER_ROLE.SUPERADMIN,
				USER_ROLE.VENDOR,
				USER_ROLE.USER,
				USER_ROLE.INDEPENDENT_USER,
			],
			element: <MyProfile />,
			route: PrivateRoute,
		},
		{
			path: "/change-password",
			name: "Change Password",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN, USER_ROLE.VENDOR, USER_ROLE.USER, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER],
			element: <ChangePassword />,
			route: PrivateRoute,
		},
		{
			path: "/maps/googlemaps",
			name: "Google Maps",
			roles: [USER_ROLE.ADMIN],
			element: <GoogleMaps />,
			route: PrivateRoute,
		},
		{
			path: "/settings/notification",
			name: "Manage Notification",
			roles: [USER_ROLE.ADMIN],
			element: <Notification />,
			route: PrivateRoute,
		},
	],
};

const maintenanceRoutes: RoutesProps = {
	path: "/maintenance",
	name: "Maintenance",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR, USER_ROLE.USER],
	icon: "uil-briefcase",
	children: [
		{
			path: "/maintenance/tickets-list",
			name: "Tickets/WOs",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR, USER_ROLE.USER],
			element: <TicketsWOsListView />,
			route: PrivateRoute,
		},
		{
			path: "/maintenance/maintenance-tasks",
			name: "Maintenance Tasks",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.VENDOR, USER_ROLE.USER],
			element: <MaintenanceTasks />,
			route: PrivateRoute,
		},
		{
			path: "/maintenance/maintenance-tasks/:id",
			name: "Detail View",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			element: <DetailView />,
			route: PrivateRoute,
		},
		// {
		// 	path: "/maintenance/maintenance-tasks/:id",
		// 	name: "Maintenance Tasks",
		// 	roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
		// 	element: <AddEditScheduledMaintenanceTasks />,
		// 	route: PrivateRoute,
		// },
        {
			path: "/maintenance/tickets-list/:id",
			name: "Tickets/WOs",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			element: <AddEditTicket />,
			route: PrivateRoute,
		},
		{
			path: "/maintenance/scheduled-maintenance",
			name: "Scheduled-Maintenance",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
			element: <ScheduleMaintenanceView />,
			route: PrivateRoute,
		},
	],
};

const managementRoutes: RoutesProps = {
	path: "/management",
	name: "Management",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
	icon: "uil-briefcase",
	children: [
	  {
		path: "/management/check-InOuts",
		name: "Check In/Outs",
		roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
		element: <CheckInOutView />,
		route: PrivateRoute,
	  },
	  {
		path: "/management/inspections", // Add a route for Inspections here
		name: "Inspections",
		roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
		element: <Inspections />,
		route: PrivateRoute
	  },
	  {
		path: "/management/inspections-v2/:id", // Add a route for Inspections here
		name: "InspectionsV1",
		roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
		element: <InspectionDetailsV1 />,
		route: PrivateRoute
	  },
	  {
		path: "/management/inspections/:id", // Add a route for Inspections here
		name: "Inspection Details",
		roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.INDEPENDENT_USER, USER_ROLE.USER, USER_ROLE.VENDOR],
		element: <InspectionDetails/>,
		route: PrivateRoute
	  },
	],
  };
 
const projectManagementRoutes: RoutesProps = {
	path: "/project-management",
	name: "Project Management",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.USER],
	element: <Projects />,
	icon: "uil-briefcase",
	children: [
		{
			path: "/project-management/:id",
			name: "Project Management",
			roles: [USER_ROLE.ADMIN],
			element: <ProjectKanban />,
			route: PrivateRoute,
		},
	],
};

const notificationRoutes: RoutesProps = {
	path: "/notification",
	name: "Notification",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN],
	icon: "uil-briefcase",
	children: [
		{
			path: "/notification/notification-preference",
			name: "Notification Preference",
			roles: [USER_ROLE.ADMIN],
			element: <NotificationPreferenceView />,
			route: PrivateRoute,
		},
	],
};

const userRoutes: RoutesProps = {
	path: "/users",
	name: "Users",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.SUPERADMIN],
	icon: "uil-briefcase",
	children: [
        {
			path: "/users/manageuser",
			name: "Manage User",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.SUPERADMIN],
			element: <ManageUsersView />,
			route: PrivateRoute,
		},
        {
			path: "/users/manageuser/:id",
			name: "Manage User Details",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.SUPERADMIN],
			element: <ManageUsersDetailsView />,
			route: PrivateRoute,
		},
		{
			path: "/users/team",
			name: "Teams",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
			element: <GroupsList />,
			route: PrivateRoute,
		},
		{
			path: "/users/team/invites",
			name: "Teams",
			roles: [USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
			element: <TeamInvites />,
			route: PrivateRoute,
		},
		{
			path: "/users/team/:id",
			name: "Teams",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
			element: <GroupForm />,
			route: PrivateRoute,
		},
		{
			path: "/users/team/dashboard/:id",
			name: "Teams Dashboard",
			roles: [USER_ROLE.ADMIN, USER_ROLE.INDEPENDENT_VENDOR, USER_ROLE.USER, USER_ROLE.INDEPENDENT_USER],
			element: <GroupsDashboard />,
			route: PrivateRoute,
		},
		{
			path: "/users/teams",
			name: "Teams",
			roles: [USER_ROLE.SUPERADMIN],
			element: <TeamListView />,
			route: PrivateRoute,
		},
		{
			path: "/users/teams/:id",
			name: "Team Details",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <TeamDetailsView />,
			route: PrivateRoute,
		},
		{
			path: "/vendors",
			name: "Vendors",
			roles: [USER_ROLE.INDEPENDENT_USER],
			element: <ManageVendors />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/user-clients",
			name: "Client Users",
			roles: [USER_ROLE.INDEPENDENT_VENDOR],
			element: <ManageClients />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/user-clients/:id",
			name: "Client Users",
			roles: [USER_ROLE.INDEPENDENT_VENDOR],
			element: <ClientForm />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/client-requests",
			name: "Client Users",
			roles: [USER_ROLE.INDEPENDENT_VENDOR],
			element: <UserRequests />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/users/vendors",
			name: "Vendors",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <VendorsListView />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/users/recommended-vendors",
			name: "Vendors",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <RecommendedVendorsList />,
			route: PrivateRoute,
			children: [],
		},
		{
			path: "/users/vendors/:id",
			name: "Vendor Details",
			roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
			element: <VendorDetailsView />,
			route: PrivateRoute,
		}
	],
};

const staticDataPath: RoutesProps = {
	path: "/static-data",
	name: "Static Data",
	route: PrivateRoute,
	roles: [USER_ROLE.SUPERADMIN],
	element: <StaticDataList />,
	icon: "uil-briefcase",
};

const staticDataDetailPath: RoutesProps = {
	path: "/static-data/:id",
	name: "Static Data Details",
	route: PrivateRoute,
	roles: [USER_ROLE.SUPERADMIN],
	element: <StaticDataDetails />,
	icon: "uil-briefcase",
};

const clientApprovalPath: RoutesProps = {
	path: "/client-approvals",
	name: "Client Approvals",
	route: PrivateRoute,
	roles: [USER_ROLE.SUPERADMIN],
	element: <ClientApprovals />,
	icon: "uil-briefcase",
};

const trackActivityPath: RoutesProps = {
	path: "/track-activity",
	name: "Track Activity",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN],
	element: <TrackActivity />,
	icon: "uil-activity",
};

const placesRoutes: RoutesProps = {
	path: "/places",
	name: "Places",
	route: PrivateRoute,
	roles: [USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN],
	icon: "uil-briefcase",
	children: [
        {
			path: "/places/countries",
			name: "Manage Countries",
			roles: [USER_ROLE.SUPERADMIN],
			element: <Countries />,
			route: PrivateRoute,
		},
        {
			path: "/places/states/:countryId",
			name: "Manage States",
			roles: [USER_ROLE.SUPERADMIN],
			element: <States />,
			route: PrivateRoute,
		},
		{
			path: "/places/cities/:stateId",
			name: "Manage Cities",
			roles: [USER_ROLE.SUPERADMIN],
			element: <Cities />,
			route: PrivateRoute,
		},
	],
};

// const staticDataPath: RoutesProps = {
// 	path: "/static-data",
// 	name: "Static Data",
// 	icon: "uil-briefcase",
// 	// header: "Navigation",
// 	// element: <AdminDashboard />,
// 	element: <AdminDashboard />,
// 	route: PrivateRoute,
// 	roles: [USER_ROLE.ADMIN, USER_ROLE.VENDOR],
// };

// const staticDataPath: RoutesProps = {
// 	path: "/static-data",
// 	name: "Static Data",
// 	route: PrivateRoute,
// 	roles: [USER_ROLE.SUPERADMIN],
// 	icon: "uil-briefcase",
// 	children: [
// 		{
// 			path: "/",
// 			name: "Static Data",
// 			roles: [USER_ROLE.SUPERADMIN],
// 			element: <StaticDataList />,
// 			route: PrivateRoute,
// 		},
// 		{
// 			path: "/:id",
// 			name: "Static Data Details",
// 			roles: [USER_ROLE.SUPERADMIN],
// 			element: <StaticDataDetails />,
// 			route: PrivateRoute,
// 		},
// 	],
// };

const error404Routes: RoutesProps = {
	path: "*",
	name: "Error 404",
	route: Route,
	element: <Error404 />,
};

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
	let flatRoutes: RoutesProps[] = [];

	routes = routes || [];
	routes.forEach((item: RoutesProps) => {
		flatRoutes.push(item);

		if (typeof item.children !== "undefined") {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

// All routes
const authProtectedRoutes = [
	dashboardRoutes,
	searchResultRoutes,
	calendarRoutes,
	telematicsRoutes,
	fleetCompleteRoutes,
	fleetReports,
    buildingsLocationRoutes,
	ResponseRequestRoute,
	UserRequestsRoute,
	assetsRoutes,
	maintenanceRoutes,
	settingsRoutes,
	userRoutes,
	managementRoutes,
	projectManagementRoutes,
	notificationRoutes,
	staticDataPath,
	staticDataDetailPath,
	clientApprovalPath,
	placesRoutes,
	trackActivityPath,
	// ...appRoutes,
	//   extrapagesRoutes,
	// uiRoutes,
	error404Routes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
	publicRoutes,
	authProtectedRoutes,
	authProtectedFlattenRoutes,
	publicProtectedFlattenRoutes,
};

