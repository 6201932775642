import { Card} from "react-bootstrap";
import { useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/linkedLogo.svg";

const RegisterConfirm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.message !== "Successfully Registered") {
      navigate("/sign-up");
    }
  }, []);
  return (
    <>
      <div className="text-center d-flex align-items-center justify-content-center mt-5 ">
        <Card style={{ width: "50%" }}>
          <div className="d-flex flex-column align-items-center justify-content-center p-2 shadow rounded">
            <Link to="/" className="logo logo-light text-center">
              <span className="logo-lg">
                <img src={Logo} alt="" height="100" />
              </span>
            </Link>
            <div className="mt-1 mb-2 text-black" style={{ fontSize: "16px" }}>
              Thanks for registering!
            </div>
            <div className=" mb-3 text-black" style={{ fontSize: "14px" }}>
              Your account has been verfied and details have been submitted for
              approval.
              <br />
              Kindly wait our approval.
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default RegisterConfirm;
